import { useQuery } from 'react-query'
import matic_logo from '../components/polygon-token-logo.png';
import './displayTips.css';
import SinglePostWallet from '../components/single_post_wallet'
import { isBrowser } from 'react-device-detect';
import { useAccount, useContractReads, useContractRead } from 'wagmi';

const DisplayTips = (props) => {
  const { address, isConnected } = useAccount()

  const { data:replyOwner, isFetchedReplyOwner } = useContractRead(
    {
      address: props.smartreplyNFTContract.toLowerCase(),
      abi: [{
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ownerOf",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
      functionName: 'ownerOf',            
      enabled: (props.smartreplyNFTContract !=="0x0000000000000000000000000000000000000000"),
      args: [ 
        props.smartreplyToken
        ],
    })

  const { data:attachOwner, isFetchedAttachOwner } = useContractRead(
    {
      address: props.smartAttachNFTContract.toLowerCase(),
      abi: [{
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ownerOf",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
      functionName: 'ownerOf',            
      enabled: (props.smartAttachNFTContract !=="0x0000000000000000000000000000000000000000"),
      args: [ 
        props.smartAttachToken
        ],
    })

  const { data:dataFees, isFetched} = useContractReads({
    
    contracts: [
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "reply_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'reply_fee',
      },
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "posting_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'posting_fee',
      },
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "quote_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'quote_fee',
      },
    ],
    enabled: isConnected,
  })

  const convertWei = (etherInWei) => {
    // convert input to BigInt
    const wei = BigInt(etherInWei.toString());
    
    // Define 1 ether = 1e18 wei as BigInt
    const oneEtherInWei = BigInt('1000000000000000000');
  
    // Convert to Ether and get the floating number representation
    const outputEther = Number(wei) / Number(oneEtherInWei);
  
    // Return output as a string with 3 decimal places
    return outputEther.toFixed(3);
  };

  // This is to allow caching of NFT metadata
  const { data: postingTip } = useQuery('getPostingTip',
                                      async () => convertWei(dataFees[1].result),
                                      {
                                        enabled: isFetched,
                                      }
                                  )
  const { data: replyTip } = useQuery('getReplyTip',
                                      async () => convertWei(dataFees[0].result),
                                      {
                                        enabled: isFetched,
                                      }
                                  )
  const { data: quoteTip } = useQuery('getQuoteTip',
                                      async () => convertWei(dataFees[2].result),
                                      {
                                        enabled: isFetched,
                                      }
                                  )     

  const { data: ownerReply, isLoading:isLoadingOwnerReply } = useQuery(['getOwnerNFT',props.smartreplyNFTContract, props.smartreplyToken],
                                      async () => {
                                        if (props.smartreplyNFTContract !=="0x0000000000000000000000000000000000000000") {
                                            return replyOwner.toLowerCase()
                                        } else {
                                            return "0x0000000000000000000000000000000000000000"
                                        }
                                      },
                                      {
                                        enabled: isFetchedReplyOwner,
                                      }
                                  )      

  const { data: ownerAttach, isLoading:isLoadingOwnerAttach } = useQuery(['getOwnerNFT',props.smartAttachNFTContract, props.smartAttachToken],
                                      async () => {
                                        if (props.smartAttachNFTContract !=="0x0000000000000000000000000000000000000000") {
                                            return attachOwner.toLowerCase()
                                        } else {
                                            return "0x0000000000000000000000000000000000000000"
                                        }
                                      },
                                      {
                                        enabled: isFetchedAttachOwner,
                                      }
                                  )   
  var showReply = false        
  
  if (!isLoadingOwnerReply)
    {
    showReply=(props.smartreplyNFTContract!=="0x0000000000000000000000000000000000000000" && props.smartreplyToken!==0
              && ownerReply!== address.toLowerCase() )
    } 

  var showQuote = false                     
  if (!isLoadingOwnerAttach)
    {
      showQuote=(props.smartAttachNFTContract!=="0x0000000000000000000000000000000000000000" && props.smartAttachToken!==0
              && ownerAttach !== address.toLowerCase() )
    } 

  return (
    <div className={isBrowser?"TipList":"TipList TipMobile"} >
                            <div className="SingleTip">
                            SmallChains Tip: {postingTip}
                                <div className="CurrentToken" >
                                    <img src={matic_logo} alt={"logo"} width={12} />
                                </div>
                            </div>
        {showReply && !isLoadingOwnerReply && ownerReply?<div className="SingleTip">
                            Reply Tip to 
                            <div className="TipUser"><SinglePostWallet walletAddress={ownerReply} /></div>
                            : {replyTip}           
                                <div className="CurrentToken" >
                                    <img src={matic_logo} alt={"logo"} width={12} />
                                </div>
                            </div>
                        :""}
        {showQuote && !isLoadingOwnerAttach && ownerAttach?<div className="SingleTip">
                            Quote Tip to 
                            <div className="TipUser"><SinglePostWallet walletAddress={ownerAttach} /></div>
                            : {quoteTip}            
                                <div className="CurrentToken" >
                                    <img src={matic_logo} alt={"logo"} width={12} />
                                </div>
                            </div>
                        :""}
    </div>
    )
}

DisplayTips.defaultProps = {
    smartreplyNFTContract:"0x0000000000000000000000000000000000000000",
    smartAttachNFTContract:"0x0000000000000000000000000000000000000000",
    smartreplyToken:0,
    smartAttachToken:0,
  }

export default DisplayTips;