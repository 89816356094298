import "antd/dist/antd.css";
import './App.css';
import './MainWindow.css';
import Profile from "./pages/profile";
import Post from "./pages/post";
import Home from './pages/home'
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Topbar from "./components/Topbar";
import { useEffect } from "react";
import { isBrowser } from 'react-device-detect';
import Notifications from './pages/notifications'
import { useAccount } from 'wagmi';

function App() {  
  const { address, isConnected } = useAccount()

  useEffect(() => {
    document.title = process.env.REACT_APP_WEBSITE_TITLE
  }, []);

    return (
      <div className="App">
        <Router>     
            <div className={isBrowser?"MainWindow":"MainWindow MainWindow-mobile"}>  
              <Routes>
                <Route path="/post/:postid" element={<Post />} />       
                <Route exact path="/" element={<Home />} />
                <Route path="/profile/:wallet" element={<Profile />} />
                {isConnected && address?<Route path="/notifications" element={<Notifications key={0}/* {user.get('latestviewblock')} */ blockNumber={0}/* {user.get('latestviewblock')} */ walletAddress={address} />} />:""}   
              </Routes>                
            </div>       
            <Topbar />
        </Router>
      </div>
    );
}

export default App;
