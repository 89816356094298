import { useState } from "react";
import {BsFillPlusCircleFill} from 'react-icons/bs';
import ClipLoader from "react-spinners/ClipLoader";
import ModalPost from "./modalPost"
import SinglePostTree from "./single_post_tree"
import ModalLogin from './modalLogin'
import { isBrowser } from 'react-device-detect';
import { useAccount } from 'wagmi';

const ReplyerEmptyFace = (props) => {
  const { isConnected } = useAccount()
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (isModalVisible === true) {
    return (  
      <div className="SingleFace">
          <div className={isBrowser?"SingleFaceReply":"SingleFaceReply SingleFaceReply-mobile"} >    
            <BsFillPlusCircleFill className="middle_circle" size={30} />
            <ClipLoader className="middle_circle_wait" size={30} color="#2BCDE4" />
            <ModalPost smartReplyNFTContract={props.smartReplyNFTContract} smartReplyToken={props.smartReplyToken} parentUpdate={props.parentUpdate} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
          </div>
        {props.showBranch?<SinglePostTree seedPost={false} openEnd={true} />:""}
      </div>
    )
  } 
  else if (!isConnected) {
    return (      
          <div className="SingleFace">
            <div className={isBrowser?"SingleFaceReply":"SingleFaceReply SingleFaceReply-mobile"} > 
              <div className="AccountButton" >                
                <div className="middle_circle">           
                  <ModalLogin  size={30}/>            
                </div>
              </div>
            </div>
            {props.showBranch?<SinglePostTree nbOpenThread={props.nbOpenThread} seedPost={false} openEnd={true} />:""}
          </div>
            )
  } else {
    return (
      <div className="SingleFace">
            <div className={isBrowser?"SingleFaceReply":"SingleFaceReply SingleFaceReply-mobile"}>   
              <BsFillPlusCircleFill className="middle_circle" size={30}  onClick={() => {
                                                      setIsModalVisible(true);
                                                    }}/>
            </div>    
        {props.showBranch?<SinglePostTree nbOpenThread={props.nbOpenThread} openEnd={true} />:""}
      </div>       
    )
  }
}

if (isBrowser) {
  ReplyerEmptyFace.defaultProps = {
    smartReplyNFTContract:  "0x0000000000000000000000000000000000000000",
    smartReplyToken: 0,
    smartAttachNFTContract:  "0x0000000000000000000000000000000000000000",
    smartAttachToken: 0,
    showBranch: false,
    nbOpenThread:0
  }
} else {
  ReplyerEmptyFace.defaultProps = {
    smartReplyNFTContract:  "0x0000000000000000000000000000000000000000",
    smartReplyToken: 0,
    smartAttachNFTContract:  "0x0000000000000000000000000000000000000000",
    smartAttachToken: 0,
    showBranch: false,
    nbOpenThread:0
  }
}

export default ReplyerEmptyFace;