import {HiArrowSmRight} from "react-icons/hi"
import './profileTipsOut.css';
import {ImDiamonds} from "react-icons/im"
import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const ProfileTipsOut = (props) => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','NFTTipped'), 
      where("NFTprofileSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()), 
      where("giver", '==', props.walletAddress.toLowerCase()));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
      });
    return () => unsubscribe();
  }, [props.walletAddress]);

    return (
        <div className="number_tips_out">
           <div className="diamond_out"><ImDiamonds width="30px" /></div><div className="arrowRight"><HiArrowSmRight width="30px"/></div>{data.length}
        </div>
    )
  }
  
  export default ProfileTipsOut