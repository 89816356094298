import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {AiFillCamera} from "react-icons/ai";
import './Post_camera_nft.css';
import { v4 as uuidv4 } from 'uuid';
import Webcam from "react-webcam";
import {Button} from '@material-ui/core';
import { notification } from 'antd';
import { isBrowser } from 'react-device-detect';
import {MdFlipCameraIos} from 'react-icons/md'
import { useEffect, useCallback } from "react";
import { useAccount, useBalance, useContractRead, useContractWrite, useWaitForTransaction } from 'wagmi';
import { useQuery } from 'react-query'
import { getFunctions, httpsCallable } from "firebase/functions";
import { decodeEventLog, parseAbi } from 'viem'

const PostCameraNFT = (props) => {
  const { address } = useAccount()

  const { data:userBalance } = useBalance({
    address: address
  })

  const [waiting, setWaiting] = useState(false);
  const [photoData, setphotoData] = useState('')
  const webcamRef = React.useRef(null);
  const [facingMode, setFacingMode] = useState("user")
  const [list_devices, setDevices] = useState([])
  const [currentCamera, selectCamera] = useState("")

  var videoConstraints = {}
  if (facingMode === "environment") {
    videoConstraints = {
      width: 300,
      height: 300,
      facingMode: {exact: "environment" }
      };  
  } else if (currentCamera === "") {
    videoConstraints = {
      width: 300,
      height: 300,
      facingMode: {exact: "user" }
      };  
  } else {
    videoConstraints = {
      width: 300,
      height: 300,
      deviceId: currentCamera
      };  
  }

  const handleDevices = useCallback(
    mediaDevices =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );

  const switchCamera = () => {
    if (facingMode === "user") {
      setFacingMode("environment")
    } else {
      setFacingMode("user")
    }
  }

  const { refetch:refetchFee } = useContractRead(
    {
      address: process.env.REACT_APP_POLYGON_SMALL_CAMS_CONTRACT,
      abi: [{
        "inputs": [],
        "name": "minting_fee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
      }],
      functionName: 'minting_fee',            
      enabled: false
  })

  const { isLoading:isLoadingMintingFee, data: mintingFee } = useQuery(['cam_minting_fee'], 
    () => refetchFee().then((value)=>{return value['data'].toString()}))
  
  const { data, write:mintNFT} = useContractWrite({
      address: process.env.REACT_APP_POLYGON_SMALL_CAMS_CONTRACT,
      abi: [{
        "inputs": [
          {
            "internalType": "string",
            "name": "_tokenURI",
            "type": "string"
          }
        ],
        "name": "mintCam",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "payable",
        "type": "function",
        "payable": true
      }],
      functionName: 'mintCam',
      value: mintingFee,
      args: [        
        " "
      ],
      enabled: !isLoadingMintingFee,
      onError(error) {
        notification
        .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
      },
      onSuccess(data) {
        notification
        .success({message:  'NFT was approved (hash: '+data['hash']+').', duration: 8, placement:'topLeft'})
      }
  })

  const { data:dataReceipt } = useWaitForTransaction({
    hash: data?.hash,
  })

  if (dataReceipt && waiting) {
    var local_logs = dataReceipt['logs'][0]
    let local_abi = parseAbi([ "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"]);
    let log = decodeEventLog({ 
      abi: local_abi,
      data: local_logs['data'], 
      topics: local_logs['topics']
    })
    const giventokenId = log.args.tokenId.toString()
    props.settmpImg(photoData)
    props.setContractToken(parseInt(giventokenId))
    props.setContract(process.env.REACT_APP_POLYGON_SMALL_CAMS_CONTRACT)
    setWaiting(false);                                                            
    props.setIsModalVisible()
  }

  const mintCam = async (ipfs_URI) => {
    notification
    .info({message: 'Minting of profile NFT submitted to your crypto wallet.', duration: 8, placement:'topLeft'})
    mintNFT({
      args: [ipfs_URI],
      }
    )
    notification
    .info({message: 'NFT was submitted to the blockchain. Waiting for block confirmation.', duration: 8, placement:'topLeft'})

  }

  const mintphoto = async () => {
    
    var filename_png = uuidv4()

    var abi = [
      {
          path: filename_png+'.png',
          content: photoData,
      },
      ];
    
    notification
    .info({message: 'Raw image is uploading to InterPlanetary File System (IPFS).', duration: 8, placement:'topLeft'})

    const functions = getFunctions();
    const uploadipfs = httpsCallable(functions, 'uploadipfs');
    const res = await uploadipfs({ abi: abi })
    var file_png = res.data

    notification
    .success({message: 'Raw image uploaded (ipfs: '+file_png+')', duration: 8, placement:'topLeft'})

    var filename_json = uuidv4()

    abi = [
      {
          path: filename_json+'.json',
          content: {
            description: "None", 
            image: file_png, 
            name: "None",
            attributes: "None", 
          }
      },
      ];

    const res_json = await uploadipfs({ abi: abi })
    var path_ipfs_json = res_json.data

    notification
    .success({message: 'NFT metadata uploaded (ipfs: '+path_ipfs_json+')', duration: 8, placement:'topLeft'})

    await mintCam(path_ipfs_json)
  }

  const capture = React.useCallback(
    () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setphotoData(imageSrc)
    }, [webcamRef]);


  const onSubmitPressed = async () => {
    const balance = parseFloat(userBalance['formatted']).toFixed(4)
    if (balance === '0') {
          notification
          .error({message: 'Your wallet is empty, you need some token to pay for micro-payments.', duration: 8, placement:'topLeft'})
        } else { 
        setWaiting(true); 
        mintphoto()
      };
    }

  return (
    <>
        <div className="CameraPoster">
          <div className="CameraTaker">                    
              {photoData === '' ? <Webcam
                  ref={webcamRef}
                  height="300"
                  videoConstraints={videoConstraints}
              /> : <img src={photoData} alt="webcam" />}
          </div>
          {isBrowser?"":
          <div className="CameraSwitch" onClick={()=> {switchCamera()}}>
            <MdFlipCameraIos size={40} />
          </div>
          }
          <div className="ImageCam">
            {photoData !== '' ?
                <Button variant="contained"  color="secondary" size="small" onClick={(e) => {
                    setphotoData('')
                }}
                    className="webcam-btn">
                    Retake Image</Button> :
                <Button variant="contained" color="secondary" size="small" onClick={(e) => {
                    capture();
                }}
                    className="webcam-btn">Capture</Button>
            }
          </div>
          {(isBrowser && list_devices.length>1)?
          <div className="CameraList">          
            {list_devices.map(function(single_device, index){ return (
                <div className="CameraNumber" onClick={()=> {selectCamera(single_device.deviceId)}}>
                    <AiFillCamera size="32px" className="CameraNFTIcon" />
                    {index}
                </div>
                )
              }
              )
            }
          </div>   
          :""
          }
        </div> 
        {photoData !== '' ?
          <div className="CameraSubmit">
              <button className="PostStatusInputSubmitButton" disabled={waiting} onClick={() => {
                                                              onSubmitPressed();
                                                          }}>
                  {(waiting)?<>
                              <ClipLoader size="20px"/> Mint NFT
                             </>
                             :
                             "Mint NFT"}
              </button>
          </div> : ""}
    </>
    )
}

export default PostCameraNFT;