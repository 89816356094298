import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';
import { useState, useEffect} from "react";

const RepliesIn = (props) => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','PostMinted'), 
      where("replyNFTSmartContract", '!=', "0x0000000000000000000000000000000000000000"), 
      where("Author", '==', props.walletAddress.toLowerCase())
      );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
      });
    return () => unsubscribe();
  }, [props.walletAddress]);

    return (
    <>
        Reply posts : {data.length}
    </>
    )
  }
  
  export default RepliesIn