import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import './Post_upload.css';
import { v4 as uuidv4 } from 'uuid';
import { notification } from 'antd';
import { useAccount, useBalance, useContractRead, useContractWrite, useWaitForTransaction } from 'wagmi';
import { useQuery } from 'react-query'
import FileUpload from './file_upload.js'
import { getFunctions, httpsCallable } from "firebase/functions";

const PostUpload = (props) => {
  const { address } = useAccount()
  const [filePath, setfilePath] = useState('')
  const { data:userBalance } = useBalance({
    address: address
  })

  const [waiting, setWaiting] = useState(false);

  const { refetch:refetchFee } = useContractRead(
    {
      address: process.env.REACT_APP_POLYGON_SMALL_CAMS_CONTRACT,
      abi: [{
        "inputs": [],
        "name": "minting_fee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
      }],
      functionName: 'minting_fee',            
      enabled: false
  })

  const { isLoading:isLoadingMintingFee, data: mintingFee } = useQuery(['cam_minting_fee'], 
    () => refetchFee().then((value)=>{return value['data'].toString()}))
  
  const { data, write:mintNFT} = useContractWrite({
      address: process.env.REACT_APP_POLYGON_SMALL_CAMS_CONTRACT,
      abi: [{
        "inputs": [
          {
            "internalType": "string",
            "name": "_tokenURI",
            "type": "string"
          }
        ],
        "name": "mintCam",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "payable",
        "type": "function",
        "payable": true
      }],
      functionName: 'mintCam',
      value: mintingFee,
      args: [        
        " "
      ],
      enabled: !isLoadingMintingFee,
      onError(error) {
        notification
        .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
      },
      onSuccess(data) {
        notification
        .success({message:  'NFT was approved (hash: '+data['hash']+').', duration: 8, placement:'topLeft'})
      }
  })

  const { data:dataReceipt } = useWaitForTransaction({
    hash: data?.hash,
  })

  if (dataReceipt && waiting) {
    var local_logs = dataReceipt['logs'][0]
    let local_abi = parseAbi([ "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"]);
    let log = decodeEventLog({ 
      abi: local_abi,
      data: local_logs['data'], 
      topics: local_logs['topics']
    })
    const giventokenId = log.args.tokenId.toString()
    props.settmpImg(photoData)
    props.setContractToken(parseInt(giventokenId))
    props.setContract(process.env.REACT_APP_POLYGON_SMALL_CAMS_CONTRACT)
    setWaiting(false);                                                            
    props.setIsModalVisible()
  }

  const mintCam = async (ipfs_URI) => {
    notification
    .info({message: 'Minting of profile NFT submitted to your crypto wallet.', duration: 8, placement:'topLeft'})
    
    mintNFT({
      args: [ipfs_URI]
      }
    )
    notification
    .info({message: 'NFT was submitted to the blockchain. Waiting for block confirmation.', duration: 8, placement:'topLeft'})

  }

  async function readFile(locafile) {
    var reader = new FileReader();
    reader.readAsDataURL(locafile);
    return new Promise((resolve, reject) => {
        reader.onloadend = function() {
            if (reader.error) {
                reject(reader.error);
            } else {
                resolve(reader.result);
            }
        };
    });
  }

  const mintphoto = async () => {
    
    var filename_img = uuidv4()

    var base64 = await readFile(filePath)
    var extension = filePath.name.split(".").pop();
    
    var abi = [
      {
          path: filename_img + '.' + extension,
          content: base64
      },
      ];
    
    notification
    .info({message: 'Raw image is uploading to InterPlanetary File System (IPFS).', duration: 8, placement:'topLeft'})

    const functions = getFunctions();
    const uploadipfs = httpsCallable(functions, 'uploadipfs');
    const res = await uploadipfs({ abi: abi })
    var file_img = res.data

    notification
    .success({message: 'Raw image uploaded (ipfs: '+file_img+')', duration: 8, placement:'topLeft'})

    var filename_json = uuidv4()

    abi = [
      {
          path: filename_json+'.json',
          content: {
            description: "None", 
            image: file_img, 
            name: "None",
            attributes: "None", 
          }
      },
      ];

    const res_json = await uploadipfs({ abi: abi })
    var path_ipfs_json = res_json.data

    notification
    .success({message: 'NFT metadata uploaded (ipfs: '+path_ipfs_json+')', duration: 8, placement:'topLeft'})

    await mintCam(path_ipfs_json)
  }

  const onSubmitPressed = async () => {
    const balance = parseFloat(userBalance['formatted']).toFixed(4)
    if (balance === '0') {
          notification
          .error({message: 'Your wallet is empty, you need some token to pay for micro-payments.', duration: 8, placement:'topLeft'})
        } else { 
        setWaiting(true); 

        mintphoto()
      };
    }

    return (
        <>
            <FileUpload setfilePath={setfilePath} />
            {filePath !== '' ?
              <div className="UploadSubmit">
                  <button className="PostStatusInputSubmitButton" disabled={waiting} onClick={() => {
                                                                  onSubmitPressed();
                                                              }}>
                      {(waiting)?<>
                                  <ClipLoader size="20px"/> Mint NFT
                                 </>
                                 :
                                 "Mint NFT"}
                  </button>
              </div> : ""}
        </>
        )
    }
    
    export default PostUpload;