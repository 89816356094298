import React from "react";
import './Topbar.css';
import ReplyerEmptyFace from '../components/replyEmptyFace'
import { Link } from "react-router-dom";
import logo from '../components/logo_2022_02_19_wink_resized.png';
import {BiLogOutCircle} from "react-icons/bi"
import NotificationsButton from '../components/notifications_button'
import ModalLogin from './modalLogin'
import { isBrowser } from 'react-device-detect';
import {CgProfile} from "react-icons/cg";
import { useAccount, useDisconnect } from 'wagmi';
import { ReactSession } from 'react-client-session';

function Topbar() { 
    const { address, isConnected } = useAccount()
    const { disconnect } = useDisconnect()
    ReactSession.setStoreType("localStorage");

    var max_block = 0
    if (isConnected) {
        if (ReactSession.get(address)) {
            max_block = ReactSession.get(address);
        }
    } else if (ReactSession.get("smallchains_unsigned")) {
        max_block = ReactSession.get("smallchains_unsigned")
        }
        
    return (
      <div className={isBrowser?"Topbar":"Topbar Tobbar-mobile"}>  
        <div className={isBrowser?"Topbar-Container":"Topbar-Container Tobbar-Container-mobile"}>  
            <div className="TopbarLogoLink" onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                <Link to="/">   
                    <div className="toplogo">
                        <img src={logo} alt="smallface logo" className="logo-top" width="45px" />
                    </div>      
                </Link>
            </div>            
            {!isConnected?
                <div className = "TopbarButton">
                    <div className = "TopbarIcon">
                        <ModalLogin size="28px"/>
                    </div>                
                </div>
            :!address?
                <div className = "TopbarButton">
                    <div className = "TopbarIcon">
                        <ModalLogin size="28px"/>
                    </div>                
                </div>
            :
            <>
                <div className = "TopbarButton" onClick={() => {                                                                
                                                                disconnect()                                                             
                                                                }}>
                    <div className = "TopbarIcon">
                        <BiLogOutCircle size="28px" />
                    </div>                
                </div>  
                <Link to={"/profile/"+address.toLowerCase()}>            
                        <div className = "TopbarButton" onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>                        
                            <div className = "TopbarIcon">
                                <CgProfile size="28px" />   
                            </div>
                        </div>
                </Link>      
                <Link to="/notifications">                
                    <div className = "TopbarButton" onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>    
                        <div className = "TopbarIcon">
                            <NotificationsButton size={"28px"} key={max_block} blockNumber={max_block} walletAddress={address} />
                        </div>
                    </div>
                </Link>           
                <div className="Topbar-Poster">            
                    <ReplyerEmptyFace replytoPostId={0}/>            
                </div>                 
            </>
            }      
          </div>
    </div>
  );
}
export default Topbar;
