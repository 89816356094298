import './single_post_time.css';
import { Popover } from 'antd';
import { isBrowser } from 'react-device-detect';

const SinglePostTime = (props) => {
    const calculateSince = (datetime) =>
      {
          var tTime=new Date(datetime*1000);
          var cTime=new Date();
          var sinceMin=Math.round((cTime-tTime)/60000);
          var since = '';
          var sinceSec = 0;
          if(sinceMin === 0)
          {
              sinceSec=Math.round((cTime-tTime)/1000);
              if(sinceSec<10)
                since='less than 10 seconds ago';
              else if(sinceSec<20)
                since='less than 20 seconds ago';
              else
                since='half a minute ago';
          }
          else if(sinceMin === 1)
          {
              sinceSec=Math.round((cTime-tTime)/1000);
              if(sinceSec === 30)
                since='half a minute ago';
              else if(sinceSec<60)
                since='less than a minute ago';
              else
                since='1 minute ago';
          }
          else if(sinceMin<45)
              since=sinceMin+' minutes ago';
          else if(sinceMin>44&&sinceMin<60)
              since='about 1 hour ago';
          else if(sinceMin<1440){
              var sinceHr=Math.round(sinceMin/60);
          if(sinceHr === 1)
            since='about 1 hour ago';
          else
            since='about '+sinceHr+' hours ago';
          }
          else if(sinceMin>1439&&sinceMin<2880)
              since='1 day ago';
          else
          {
              var sinceDay=Math.round(sinceMin/1440);
              since=sinceDay+' days ago';
          }
          return since;
      };
    
    const createBlockSummary = () =>{
        const postHash = props.postData['transactionHash']
        const link_hash = process.env.REACT_APP_POLYGON_EXPLORER+"tx/"+postHash
        const link_block = process.env.REACT_APP_POLYGON_EXPLORER+"block/"+props.postData['blockNumber']
        const date_data= new Date(props.postData['blockTimestamp']*1000)
        const link_pic_contract = process.env.REACT_APP_POLYGON_EXPLORER+"address/"+postContract
        if ('attachNFTSmartContract' in props.postData) {        
          var postContract = props.postData['attachNFTSmartContract']
          var picToken = props.postData['attachNFTTokenId']
        } else {
          var postContract = "0x0000000000000000000000000000000000000000"
          var picToken = "0"
        }
        const postToken = props.postData['PostId']
        const link_contract = process.env.REACT_APP_POLYGON_EXPLORER+"address/"+process.env.REACT_APP_POLYGON_CONTRACT
          return (
              <>
                <div className="ChainProfileItem">
                    NFT post contract: <a href={link_contract} target="_blank" rel="noreferrer" >
                    {process.env.REACT_APP_POLYGON_CONTRACT.substring(0, 4)+"..."+process.env.REACT_APP_POLYGON_CONTRACT.substring(process.env.REACT_APP_POLYGON_CONTRACT.length-4)}</a>
                </div>
                <div className="ChainProfileItem">
                    NFT post token: {postToken}
                </div>
                
                {postContract!=="0x0000000000000000000000000000000000000000"?
                  <>
                    <div className="ChainProfileItem">
                        NFT attachment contract: <a href={link_pic_contract} target="_blank" rel="noreferrer" >
                        {postContract.substring(0, 4)+"..."+postContract.substring(postContract.length-4)}</a>
                    </div>
                    <div className="ChainProfileItem">
                        NFT attachment token: {picToken}
                    </div>
                  </>
                :""}

                <div className="ChainProfileItem">
                    Transaction hash: <a href={link_hash} target="_blank" rel="noreferrer" >
                    {postHash.substring(0, 4)+"..."+postHash.substring(postHash.length-4)}</a>
                </div>
                <div className="ChainProfileItem">
                    Transaction block: <a href={link_block} target="_blank" rel="noreferrer" >
                    {props.postData['blockNumber']}</a>
                </div>
                <div className="ChainProfileItem">
                    Transaction time: {date_data.toString()}
                </div>
              </>
              )
      }

        return (
            <>
                {(props.postData!==0)?<div className="BlockTime">
                                        <Popover trigger={isBrowser?"hover":"click"} overlayInnerStyle={{borderRadius: '5px', border:'1px solid black'}} content={createBlockSummary()}>
                                            {calculateSince(props.postData["blockTimestamp"])}
                                        </Popover>
                                      </div>
                                    :
                                    ""}
                    
            </>
        )
}

SinglePostTime.defaultProps = {
  postData:0,
}

export default SinglePostTime;