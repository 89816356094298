import './profileStatus.css';
import {
    query,
    collection,
    where,
    onSnapshot,
    orderBy
  } from "firebase/firestore";
  import {db} from '../components/firestore_db';
  import { useState, useEffect} from "react";

const ProfileStatus = (props) => {
    const [data, setdata] = useState([]);

    useEffect(() => {
        const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
        where("name", '==','ProfileStatusPosted'), 
        where("Author", '==',props.walletAddress.toLowerCase()),
        orderBy("blockNumber", "desc")
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
            }));
            setdata(newData);
        });
        return () => unsubscribe();
    }, [props.walletAddress]);
    if (data === undefined || data.length===0) {
        return ""
    } else {
    return (
        <div className="StatusProfile">
            {data[0]['status']} 
        </div>
    )
  }
}
  export default ProfileStatus