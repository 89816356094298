import WalletCard from "./wallet_card";
import { useQuery } from 'react-query'
import {
  query,
  collection,
  where,
  getDocs
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const ListSupporters =  (props) => {
    const GetListSupporters = async () => {
        const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
            where("name", '==','NFTTipped'), 
            where("receiver", '==', props.walletAddress.toLowerCase()),
            where("giver", '!=', props.walletAddress.toLowerCase())
            );

        const results = await getDocs(query_data);

        let uniqueFields = new Set();
        results.docs.forEach(doc => {
            uniqueFields.add(doc.data().giver);
        });
        var list_givers = Array.from(uniqueFields);
    
        return list_givers;
      }
  
    const { data:SupporterData } = useQuery(['listSupporters',
        props.walletAddress.toLowerCase()
        ], 
        async () => await GetListSupporters(props.postid)
    )

    if (SupporterData) {
        return (
            <>
                {
                    SupporterData.map(function(single_wallet, index){ 
                        return (
                            <WalletCard key={single_wallet.toLowerCase()} walletAddress={single_wallet.toLowerCase()} />  
                        )
                    })
                }
            </>
        )
    } else {
        return (
            <></>
        )
    }
  }
  
  export default ListSupporters