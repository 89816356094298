import { useState } from "react";
import './Post_attach_nft.css';
import {  Input, Button  } from 'antd';

const PostAttachNFT = (props) => {
  const [uriNFT, seturiNFT] = useState("");

  const extract_link = () => {
    try {
      const current_url = uriNFT.split(/[/:?]+/);

      if (uriNFT.includes('opensea.io/assets/matic')) {
        const contract = current_url[4]
        const token = current_url[5]
        return [contract, token]
      } else if (uriNFT.includes('rarible.com/token/polygon')) {
        const contract =  current_url[4]
        const token = current_url[5]
        return [contract, token]
      } 
      return []
    } catch (error) {
      alert("Error: " + error.code + " " + error.message)
      return []
    }
  }
  return (
    <>
        <div className="AttachModal">
            <Input 
                placeholder="Copy/Paste link to polygon NFT on OpenSea, Rarible" 
                value={uriNFT}
                onChange={(event) => {seturiNFT(event.target.value)}}
            />
            <Button className="PostStatusInputSubmitButton" type="primary" onClick={() => {
                const found_data = extract_link()
                if (found_data.length>1) {
                  props.setContractToken(found_data[1])
                  props.setContract(found_data[0])
                }
                props.setIsModalVisible()
              }}>
                Attach NFT
            </Button>
        </div>
    </>
    )
}

PostAttachNFT.defaultProps = {
  setIsModalVisible: () => {}
}

export default PostAttachNFT;