import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const ChainsIn = (props) => {
  const [data, setdata] = useState([]);
    
  useEffect(() => {        
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','PostMinted'),
      where("Author", '==', props.walletAddress.toLowerCase()), 
      where("replyNFTSmartContract", '==', "0x0000000000000000000000000000000000000000"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
      });
    return () => unsubscribe();
  }, [props.walletAddress]);

    return (
    <>
        Chains started on SmallChains: {data.length}
    </>
    )
  }
  
  export default ChainsIn