import './Notification.css';
import SinglePost from "../components/single_post"
import SinglePostWallet from '../components/single_post_wallet'
import { useLayoutEffect } from "react";
import {
    query,
    collection,
    where,
    onSnapshot,
    orderBy
  } from "firebase/firestore";
import {db} from '../components/firestore_db';
import { useState, useEffect} from "react";
import { ReactSession } from 'react-client-session';
import { useAccount } from 'wagmi'

const Notifications = (props) => {
    const [data, setdata] = useState([]);
    const { address, isConnected } = useAccount()

    ReactSession.setStoreType("localStorage");

    var max_block = 0
    if (isConnected) {
        if (ReactSession.get(address)) {
            max_block = ReactSession.get(address);
        }
    } else if (ReactSession.get("smallchains_unsigned")) {
        max_block = ReactSession.get("smallchains_unsigned")
        }
    useEffect(() => {
        const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
          where("name", '==','NFTTipped'), 
          where("receiver", '==',  props.walletAddress.toLowerCase()), 
          where("NFTprofileSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()),
          where("blockNumber", ">", max_block),
          orderBy("blockNumber", "desc")
          );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setdata(newData);
          });
        return () => unsubscribe();
      }, [props.walletAddress, max_block]);
    
   /*  const {data} = useMoralisQuery("NFTTipped", (query) =>
        {
            query.equalTo("NFTprofileSmartContract", process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase())
                .equalTo("receiver", props.walletAddress.toLowerCase())
                .descending("block_number")
            return query
        },
        [],
        {
            live: true
        }
        ); */

    useEffect(() => {
        document.title = "Notifications  "+process.env.REACT_APP_WEBSITE_TITLE;  
        }, []);

    for (let local_data_index = 0; local_data_index < data.length; local_data_index++) { 
        max_block = Math.max(data[local_data_index]['blockNumber'], max_block);
        }

    useLayoutEffect(() => {
        return () => {
            if (max_block>0) {
                if (isConnected) {
                    ReactSession.set(address, max_block);
                } else {
                    ReactSession.set("smallchains_unsigned", max_block);
                    }            
            }
        }
    }, [max_block, address, isConnected]);
 
    const calculateSince = (datetime) =>
        {
            var tTime=new Date(datetime*1000);
            var cTime=new Date();
            var sinceMin=Math.round((cTime-tTime)/60000);
            var since = ''
            var sinceSec=Math.round((cTime-tTime)/1000);

            if(sinceMin === 0)
            {
                if(sinceSec<10)
                  since='less than 10 seconds ago';
                else if(sinceSec<20)
                  since='less than 20 seconds ago';
                else
                  since='half a minute ago';
            }
            else if(sinceMin === 1)
            {
                if(sinceSec === 30)
                  since='half a minute ago';
                else if(sinceSec<60)
                  since='less than a minute ago';
                else
                  since='1 minute ago';
            }
            else if(sinceMin<45)
                since=sinceMin+' minutes ago';
            else if(sinceMin>44&&sinceMin<60)
                since='about 1 hour ago';
            else if(sinceMin<1440){
                var sinceHr=Math.round(sinceMin/60);
            if(sinceHr === 1)
                since='about 1 hour ago';
            else
                since='about '+sinceHr+' hours ago';
            }
            else if(sinceMin>1439&&sinceMin<2880)
                since='1 day ago';
            else
            {
                var sinceDay=Math.round(sinceMin/1440);
                since=sinceDay+' days ago';
            }
            return since;
        };

  return (
    <>
        <div className="NotificationAllTips">
            {data.length>0?
            <ul>
                {   
                        data.map(function(single_tip, index){ 
                            return (    
                                <div key={index} className="NotificationTipLine">
                                        <li>{calculateSince(single_tip["blockTimestamp"])}
                                            <div className="NotificationTipper">, <SinglePostWallet walletAddress={single_tip["giver"]} /></div> supported this post : <br/>
                                            <div className="TipperPost"><SinglePost showPostThreadLine={false} allowExpansion={false} seedPost={true} StripRepliersNb={0} postid={single_tip["tokenId"]} setStripRepliersNb={()=>{}}/>
                                            </div>
                                        </li>
                                </div>
                            )
                        }
                        )
                    }
            </ul>
            :<div style={{textAlign:"center"}}>No new notifications</div>}
        </div>
    </>
  )
}

Notifications.defaultProps = {
    walletAddress: "",
  }
  
export default Notifications;