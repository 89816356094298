import "./Post_emojis.css"
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

const PostEmojis = (props) => {
    
    const onEmojiClick = (event) => {
        props.editText(props.currentText+event.native)
      };

    return (                
      <div className="emojipicker"><Picker showPreview={false}  onSelect={onEmojiClick} />
      </div>
        )
}

export default PostEmojis;