import ClipLoader from "react-spinners/ClipLoader";
import './home.css';
import ListStrips from '../components/list_strips'  
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import WalletCard from "../components/wallet_card";
import { Tabs } from 'antd';
import "./profile.css";
import ListSupporters from "../components/ListSupporters";
import ListSupporting from "../components/ListSupporting";
import ModalStatus from '../components/modalStatus'
import WalletBalance from '../components/wallet_balance'
import YourStats from "../components/yourstats";
import ModalProfPicture from '../components/modalProfPicture'
import YourFaces from "../components/yourfaces";
import { useAccount } from 'wagmi';
import {
  query,
  collection,
  where,
  getDocs,
  orderBy
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const { TabPane } = Tabs; 

const Profile = (props) => {
  const { address, isConnected } = useAccount()

  const { wallet } = useParams();
  const [currentWallet, setWallet] = useState("");
  const [list_ids, setList] = useState(null);

  const only_keep_ids = (database_data) => {
    var _foundstrips = []
    for (var counter = database_data.length - 1; counter >= 0; counter--) {
        var Item = database_data[counter].data();
        _foundstrips.push(Item["PostId"])
      }
    return _foundstrips
  }
    
  const searchIds = async () => {
    const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
    where("name", '==','PostMinted'), 
    where("Author", '==', wallet.toLowerCase()), 
    orderBy("blockNumber"));

    const results = await getDocs(query_data);

    var list_ids = only_keep_ids(results.docs)    
    setList(list_ids)
  }

    if ((currentWallet !== wallet)) {    
      setWallet(wallet)
      searchIds()
    }
    
  useEffect(() => {
    document.title = "Profile "+currentWallet.substring(0, 4)+"..."+currentWallet.substring(currentWallet.length-4)+"  "+process.env.REACT_APP_WEBSITE_TITLE;  
    }, [currentWallet]);

  var your_wallet = false
  if (isConnected) {
    if (address.toLowerCase() === wallet.toLowerCase())
    {
      your_wallet = true
    }
  }

  const items = [
    {
      key: "1", 
      label: "Chains/Replies", 
      children: (
        <div className="WalletSubPanels ProfileChain">
          <ListStrips key={list_ids} list_post_ids={list_ids} />
        </div>
      )
    },
    {
      key: "2", 
      label: "Supporters", 
      children: (
        <div className="WalletSubPanels">
          <ListSupporters walletAddress={wallet} />
        </div>
      )
    },
    {
      key: "3", 
      label: "Supporting", 
      children: (
        <div className="WalletSubPanels">
          <ListSupporting walletAddress={wallet} />
        </div>
      )
    }]

    if (your_wallet) {
      items.push(
        {
          key: "4", 
          label: "Wallet", 
          children: (
            <div className="WalletSubPanels">
              <WalletBalance walletAddress={wallet} />  
              <YourStats walletAddress={wallet} />
            </div>
          )
        },
        {
          key: "5", 
          label: "NFTs", 
          children: (
            <div className="WalletSubPanels">
              <YourFaces setIsModalVisible={()=>{}} setContractToken={()=>{}}  setContract={()=>{}} />
            </div>
          )
        },    {
          key: "6", 
          label: "Edit profile", 
          children: (
            <div className="WalletSubPanels">
              <ModalProfPicture />
              <ModalStatus />
            </div>
          )
        })
    }
   
  if (list_ids !== null) {
    return (
      <div className="WholeWallet">
        <div className="ProfileCard">
          <WalletCard key={currentWallet} walletAddress={currentWallet} />  
        </div>
        <Tabs size="large" defaultActiveKey="1" items={items} centered tabBarStyle={{marginLeft: 10, marginRight:5}} />
    </div>    
    )    
    } else {
      return (
        <div className="WaitingObject">
          Fetching blockchain data<br/>
          <ClipLoader size={25} color="#2081E2" />
        </div>
        )
  }
}
export default Profile;
