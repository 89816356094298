import {useState } from "react";
import { FaRetweet } from 'react-icons/fa';
import ModalPost from "./modalPost"
import "./sharer.css"

const Sharer = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
    
return (
    <>
        {!props.readOnly?
            <>
                <div className="bubbleShare" onClick={() => {setIsModalVisible(true)}}>
                        <FaRetweet size="20px"/>
                </div>
                <ModalPost smartAttachNFTContract={process.env.REACT_APP_POLYGON_CONTRACT} smartAttachToken={props.postid} parentUpdate={props.parentUpdate} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
            </>
            :
            <div className="bubbleNoShare">
                <FaRetweet size="20px"/>
            </div>
        }
    </>
    )
}

export default Sharer;