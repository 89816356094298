import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./yourfaces.css";
import { isBrowser } from 'react-device-detect';
import NFTPic from './nft_pic'
import InfiniteScroll from "react-infinite-scroll-component";
import { useAccount } from 'wagmi'
import { useQuery } from 'react-query'
import {
  query,
  collection,
  where,
  limit,
  getDocs,
  orderBy
} from "firebase/firestore";
import {db} from '../components/firestore_db';
import { getFunctions, httpsCallable } from "firebase/functions";

const added_length = 3

const YourFaces = (props) => {  
  const { address, isConnected } = useAccount()

  const [foundfaces, setNFTData] = useState(-1);
  const [visibleNFTs, setvisibleNFTs] = useState([]);

  const fetch = async () => {
    if (isConnected) {
        const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
        where("name", '==','ProfilePicturePosted'), 
        where("Author", '==', address.toLowerCase()), 
        orderBy("blockNumber", "desc"),
        limit(1));

        const results = await getDocs(query_data);
        var firstItem = []
        if (results.docs[0]) {
            firstItem = results.docs[0].data();                
        }
        return firstItem
    } else {
        return []
    }
}

const { data } = useQuery(['ProfilePicturePosted',
                                    address.toLowerCase()
                                    ], 
                                    () => fetch()
                                )

  const fetchMoreData = () => {
    if (foundfaces!==-1) {
      const original_length = foundfaces.length
      const visible_length = visibleNFTs.length
      const new_visibleNFTs = visibleNFTs.concat(foundfaces.slice(visible_length, Math.min(original_length,visible_length+added_length)));

      setvisibleNFTs(new_visibleNFTs)
    }
  }

  const updateFaces = async () => {
    if (foundfaces === -1) {    
      const functions = getFunctions();
      const getWalletNFTs = httpsCallable(functions, 'getwalletnfts');
      const res = await getWalletNFTs({ address: address })
      setNFTData(res.data)
      setvisibleNFTs(res.data.slice(0,added_length+1))
    }
  }

  const displaySelected = (face) => { 
    if (data && data.length>0) {
      if ((face['tokenId'] === data[0].get('tokenId')) && (face['tokenAddress'].toLowerCase() === data[0].get('NFTprofileSmartContract').toLowerCase())){
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  
  const switchContract = async (tokenContract, tokenId) => { 
    props.setContractToken(tokenId)
    props.setContract(tokenContract)
    props.setIsModalVisible(false)
  }

  if (foundfaces === -1 && isConnected) {    
      updateFaces();      
    }

  if (foundfaces.length>0 && isConnected) {
    return (
            <div className="AllProfiles">
              <InfiniteScroll
                dataLength={visibleNFTs.length}
                next={fetchMoreData}
                hasMore={visibleNFTs.length!==foundfaces.length} 
                scrollThreshold="200px"
                scrollableTarget={props.verticalContainer?props.verticalContainer.current:""}
              >
                {
                    visibleNFTs.map(function(post_face, index){ 
                        return (
                          <div key={post_face["tokenAddress"]+post_face["tokenId"]} className="SingleNFTWallet">
                              <div className={isBrowser?"PictureAligned":"PictureAligned PictureAligned-mobile"} onClick={() => switchContract(post_face["tokenAddress"], post_face["tokenId"])} >
                                <NFTPic show_info={true} show_profile={props.showProfile && displaySelected(post_face)} show_send={props.showTranfer && !displaySelected(post_face)} size={isBrowser?260:140} smartcontract={post_face["tokenAddress"]} tokenid={post_face["tokenId"]} />
                              </div>  
                          </div>
                        )
                    }
                    )
                }
              </InfiniteScroll>
            </div>
            )
  } else if ((isConnected && foundfaces === -1 && (address !== null)) || (!(isConnected))) {
      return (
        <div className="WaitingObject">
          Fetching your wallet NFTs<br/>
          <ClipLoader size={25} color="#2081E2" />
        </div>
      )
  } else  {
      return (<div className="WaitingObject"><>No NFT found. Sometimes it can take a few minutes for an incoming NFT to be detected.</></div>)
  }
}

YourFaces.defaultProps = {
  showTranfer: true,
  showProfile: true,
  verticalContainer: null
}

export default YourFaces;