import {useState } from "react";
import { GoReply } from 'react-icons/go';
import ModalPost from "./modalPost"
import "./replyer.css"

const Replyer = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

    return (
      <>
        {!props.readOnly?
          <>
          <div className="bubbleReply" onClick={() => {setIsModalVisible(true)}}>
                <GoReply size="20px"/>
          </div>            
          <ModalPost smartReplyNFTContract={process.env.REACT_APP_POLYGON_CONTRACT} smartReplyToken={props.postid} parentUpdate={props.parentUpdate} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
          </>
          :
          <div className="bubbleNoReply">
            <GoReply size="20px"/>
          </div>
        }
      </>
      )
}

export default Replyer;