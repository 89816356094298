import WalletCard from "./wallet_card";
import { useQuery } from 'react-query'
import {
  query,
  collection,
  where,
  getDocs
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const ListSupporting = (props) => {
    const GetListSupporting = async () => {
        const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
            where("name", '==','NFTTipped'), 
            where("giver", '==', props.walletAddress.toLowerCase()),
            where("receiver", '!=', props.walletAddress.toLowerCase())
        );
        
        const results = await getDocs(query_data);

        let uniqueFields = new Set();
        results.docs.forEach(doc => {
            uniqueFields.add(doc.data().receiver);
        });
        var list_receivers = Array.from(uniqueFields);
    
        return list_receivers;
      }
  
    const { data:SupportingData } = useQuery(['listSupporting',
        props.walletAddress.toLowerCase()
        ], 
        async () => await GetListSupporting(props.postid)
    )

    if (SupportingData) {
        return (
            <>
                {
                    SupportingData.map(function(single_wallet, index){ 
                        return (
                            <WalletCard key={single_wallet.toLowerCase()} walletAddress={single_wallet.toLowerCase()} />  
                        )
                    })
                }
            </>
        )
    } else {
        return (
            <></>
        )
    }
  }
  
  export default ListSupporting