import { useState } from "react";
import NFTPic from './nft_pic'
import ClipLoader from "react-spinners/ClipLoader";
import SinglePostReplies from "./single_post_replies"
import SinglePostShare from "./single_post_share"
import SinglePostTips from "./single_post_tips"
import SinglePostBubble from "./single_post_bubble"
import InsideStrip from '../components/inside_strip'  
import SinglePostTree from "./single_post_tree"
import SinglePostTime from "./single_post_time"
import SinglePostEdit from "./single_post_edit"
import WalletThumb from "./wallet_thumb"
import SinglePostWallet from '../components/single_post_wallet'
import { isBrowser } from 'react-device-detect';
import { useQuery } from 'react-query'
import {
  query,
  collection,
  where,
  limit,
  getDocs
} from "firebase/firestore";
import {db} from '../components/firestore_db';
import { useAccount} from 'wagmi'

import './single_post.css';

const SinglePost = (props) => {
  const { isConnected, address } = useAccount()
  const [StripRepliersNb, setStripRepliersNb] = useState(props.StripRepliersNb);

  const getPostfromId = async (postId) => {
    const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
    where("name", '==','PostMinted'), 
    where("PostId", '==', postId), 
    limit(1));

    const results = await getDocs(query_data);
    var firstItem = results.docs[0].data();
    return firstItem;
  }; 

  const getEditfromId = async (postId) => {
    const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
    where("name", '==','PostEdited'), 
    where("PostId", '==', postId), 
    limit(1));

    const results = await getDocs(query_data);
    if (results.docs.length === 0) {
      return undefined
    }
    var firstItem = results.docs[0].data();
    return firstItem;
  }; 

  const { data:PostData, isLoading } = useQuery(['PostMinted',
    props.postid
    ], 
    () => getPostfromId(props.postid)
  )
  const { data:EditData, isLoading:isEditLoading } = useQuery(['PostEdited',
    props.postid
    ], 
    () => getEditfromId(props.postid)
  )
  
  const local_expansion_callback = () => {
      if (StripRepliersNb === 0) {
        setStripRepliersNb(Infinity)
        props.setStripRepliersNb(props.index+1)
      } else {
        setStripRepliersNb(0)
        props.setStripRepliersNb(Infinity)
      }      
  }
  const openThread = (!props.seedPost && StripRepliersNb>0 && props.allowExpansion)

  if (!isLoading && PostData && PostData !== undefined && PostData.hasOwnProperty('Author')) {
    return (
          <>
            <div className="SingleFace">
              <div className="PostOrigin">    
                <WalletThumb key={PostData["Author"]} walletAddress={PostData["Author"]} />
                <div className="PostHeader"><SinglePostWallet walletAddress={PostData["Author"]} /> minted {props.showPostTime?<SinglePostTime postData={PostData} />:""}
                {EditData?" edited ":""}{EditData && props.showPostTime?<SinglePostTime postData={EditData} />:""}</div>
              </div>            
              <SinglePostBubble mini_mode={props.mini_mode} status = {EditData?EditData["postText"]:PostData["postText"]} />   
      
              {!props.mini_mode && PostData["attachNFTSmartContract"]!=="0x0000000000000000000000000000000000000000"?
                PostData["attachNFTSmartContract"] === process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()?
                  <div className="QuotedPost"><SinglePost showPostThreadLine={false} postid={PostData["attachNFTTokenId"]} setStripRepliersNb={()=>{}}/></div>
                :
                <div className="PostPictureAligned">
                  <NFTPic show_info={true} size={isBrowser?500:300} smartcontract={PostData["attachNFTSmartContract"]} tokenid={PostData["attachNFTTokenId"]} />
                </div>
              :
              ""
              }

              <div className="postListActions">                       
                <SinglePostReplies readOnly={props.readOnly || !isConnected} allowExpansion={props.allowExpansion} stripExpansionCallback={local_expansion_callback} StripRepliersNb={StripRepliersNb} postid={props.postid} />
                <SinglePostTips readOnly={props.readOnly || !isConnected} postid={props.postid} />         
                <SinglePostShare readOnly={props.readOnly || !isConnected} postid={props.postid} />
                {isConnected?
                  PostData["Author"].toLowerCase()==address.toLowerCase()?
                    <SinglePostEdit postStatus={PostData["postText"]} postid={props.postid} />
                    :
                    ""
                  :
                  ""
                }
              </div>             
              {props.showPostThreadLine?<SinglePostTree closeEnd={props.readOnly && props.lastPost} openThread={openThread} seedPost={props.seedPost} singleNode={(StripRepliersNb===0 && props.allowExpansion && props.seedPost)} />:""}
            </div>
            {((StripRepliersNb>0 && props.allowExpansion))? <InsideStrip allowExpansion={props.allowExpansion} showPostThreadLine={props.showPostThreadLine} showPostTime={props.showPostTime} readOnly={props.readOnly} StripRepliersNb={StripRepliersNb} postid={props.postid} />:""}
          </>
            )
  } else {
    return (
            <div className="SingleFace" ><div className="ClipLoaderContainer"><ClipLoader size={100} color="#2BCDE4" /></div></div>
            )
    }
  }

if (isBrowser) {
  SinglePost.defaultProps = {
    StripRepliersNb: 0,
    allowExpansion:true,
    seedPost:false,
    showPostTime:true,
    showPostThreadLine:true,
    readOnly:false,
    lastPost:false,
    img_size: 200,
    mini_mode: false
  }
} else {
  SinglePost.defaultProps = {
    StripRepliersNb: 0,
    allowExpansion:true,
    seedPost:false,
    showPostTime:true,
    showPostThreadLine:true,
    readOnly:false,
    lastPost:false,
    img_size: 175,
    mini_mode: false
  }
}

export default SinglePost;