import { useState, useRef } from "react";
import { Modal } from "antd";
import {FaWallet} from "react-icons/fa";
import './modalPost_gallery_nft.css';
import YourFaces from "./yourfaces";
import { isBrowser } from 'react-device-detect';

const ModalPostGalleryNFT = (props) => {
  const [modalVisible, setIsModalVisible] = useState(false);
  const list_container = useRef(null);
  return (
    <>
      <div className="ProfileNFT" style={{width:props.size}} onClick={() => setIsModalVisible(true)}>
        <FaWallet size={props.size} className="ProfileNFTIcon" />
      </div>
    <Modal
        open={modalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        zIndex={1000}
        centered={true}
        bodyStyle={{
        padding: "15px",
        }}
        width={isBrowser?"600px":"400px"}
    >
      <div className="NFTContainer" ref={list_container}>
        <YourFaces verticalContainer={list_container} showProfile={props.showProfile} setIsModalVisible={setIsModalVisible} showTranfer={false} setContractToken={props.setContractToken} setContract={props.setContract} />
      </div>
    </Modal>
    </>
    )
}

ModalPostGalleryNFT.defaultProps = {
  size: "30px",
  setIsModalVisible: () => {}
}

export default ModalPostGalleryNFT;