
import './wallet_balance.css';
import { useBalance } from 'wagmi'

const WalletBalance = (props) => {
    const link_wallet = process.env.REACT_APP_POLYGON_EXPLORER+"address/"+props.walletAddress;
    const { data:userBalance, isLoading:isBalanceLoading } = useBalance({
        address: props.walletAddress
      })
    
    return (
        <div className="WalletDetails">
            <div className="WalletItem">Wallet address: <a href={link_wallet}>{props.walletAddress}</a></div>
            <div className="WalletItem">Wallet native balance: {isBalanceLoading?"...":userBalance['formatted']}</div>
        </div>
    )
}

export default WalletBalance;