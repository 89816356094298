import ClipLoader from "react-spinners/ClipLoader";
import Sharer from './sharer'
import "./single_post_share.css"
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';
import { useState, useEffect} from "react";
import { useAccount } from 'wagmi';

const SinglePostShare = (props) => {
  const { isConnected } = useAccount()

  const [data, setdata] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','PostMinted'), 
      where("attachNFTTokenId", '==',props.postid), 
      where("attachNFTSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    return () => unsubscribe();
  }, [props.postid]);
  
if (!isLoading) {
        return (
          <>
            <div className="bubbleNbShares">  
                <Sharer readOnly={!isConnected || props.readOnly} postid={props.postid} />
                <div className="shareNb">                
                    {(data.length).toString()}
                </div>
            </div>                
          </>
            )
  }
  else {
        return (
            <div className="bubbleNbShares">
                <ClipLoader size={20} color="#2BCDE4" />
            </div>
        )
  }
}

export default SinglePostShare;