import { useState, useRef } from "react";
import { Modal, Divider } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import PostAttachNFT from '../components/Post_attach_nft'
import { notification } from 'antd';
import './modalPost.css';
import { isBrowser } from 'react-device-detect';
import SinglePost from "./single_post"
import WalletThumb from "./wallet_thumb"
import NFTPic from './nft_pic'
import DisplayTips from './displayTips'
import YourFaces from "./yourfaces";
import PostCameraNFT from "./Post_camera_nft";
import PostUpload from "./Post_upload";
import { FaWallet } from "react-icons/fa";
import { AiFillCamera } from "react-icons/ai";
import { BsFillEmojiWinkFill } from "react-icons/bs"
import PostEmojis from "./Post_emojis";
import { ImFolderUpload } from 'react-icons/im'
import {ImAttachment} from "react-icons/im";
import { useAccount, useBalance, useContractWrite, useContractReads} from 'wagmi'

const ModalPost = (props) => {
  const { address, isConnected, isConnecting } = useAccount()
  const [postStatus, setPostStatus] = useState(props.postStatus);
  const [waiting, setWaiting] = useState(false);
  const [smartreplyNFTContract, ] = useState(props.smartReplyNFTContract);
  const [smartreplyToken, ] = useState(props.smartReplyToken);
  const [smartAttachNFTContract, setAttachContract] = useState(props.smartAttachNFTContract);
  const [smartAttachToken, setAttachContractToken] = useState(props.smartAttachToken);
  const [smartEditToken, _] = useState(props.smartEditToken);
  const [tmpNFT, settmpNFT] = useState();

  const { data:userBalance, isLoading:isBalanceLoading } = useBalance({
    address: address
  })

  const getBalances = ()=>{  
    var wallet = 0
    if (!isBalanceLoading) {
      wallet = parseFloat(userBalance['formatted'])
      }  
    return wallet
  }

  const subpanel_container = useRef(null);
  const [subPanel, setsubPanel] = useState("");

  const InputRef = useRef(null);
  
  const { data:dataFees, isError:isErrorFees, isLoading:isLoadingFees } = useContractReads({
    contracts: [
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "reply_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'reply_fee',
      },
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "posting_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'posting_fee',
      },
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "quote_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'quote_fee',
      },
    ], onError(error) {
      notification
      .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
    }
  })

  const getReplyFee =  () => {
    var replyFee = BigInt(0)
    if (!isErrorFees && !isLoadingFees) {
      replyFee = dataFees[0].result
    } 
    return replyFee
  }

  const getPostingFee =  () => {
    var postingFee = BigInt(0)
    if (!isErrorFees && !isLoadingFees) {
      postingFee = dataFees[1].result
    } 
    return postingFee
  }

  const getQuoteFee =  () => {
    var quoteFee = BigInt(0)
    if (!isErrorFees && !isLoadingFees) {
      quoteFee = dataFees[2].result
    } 

    return quoteFee
  }

  const getSumFee = () => {

    const postingFee = getPostingFee()
    const replyFee = getReplyFee()
    const quoteFee = getQuoteFee()

    var sumFee = postingFee;

    if (smartEditToken==0 && replyFee && smartreplyNFTContract !== "0x0000000000000000000000000000000000000000") {
      sumFee = sumFee + replyFee
    } 

    if (smartEditToken==0 && quoteFee && smartAttachNFTContract !== "0x0000000000000000000000000000000000000000") {
      sumFee = sumFee + quoteFee
    }

    return sumFee
  }

  const { write:submitPost } = useContractWrite({
    address: process.env.REACT_APP_POLYGON_CONTRACT,
    abi: [{
      "inputs": [
        {
          "internalType": "string",
          "name": "postText",
          "type": "string"
        },
        {
          "internalType": "address",
          "name": "replyNFTSmartContract",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "replyNFTTokenId",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "attachNFTSmartContract",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "attachNFTTokenId",
          "type": "uint256"
        }
      ],
      "name": "mintPost",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    }],
    functionName: 'mintPost',
    value: getSumFee().toString(),
    enabled: (!isLoadingFees && !isConnecting),
    args: [        
      postStatus,
      smartreplyNFTContract,
      smartreplyToken, 
      smartAttachNFTContract, 
      smartAttachToken
    ],
    onError(error) {
      notification
      .error({message: "Error: " + error.code + " " + error.message, duration: 10, placement:'topLeft'})
    },
    onSuccess(data) {
      notification
      .success({message:  'NFT was submitted to the blockchain (hash: ). '+data['hash']+').', duration: 2, placement:'topLeft'})
      notification
      .info({message:  'Website will be updated when block is approved.', duration: 5, placement:'topLeft'})
    }
    })

  const { write:editPost } = useContractWrite({
    address: process.env.REACT_APP_POLYGON_CONTRACT,
    abi: [{
      "inputs": [
        {
          "internalType": "uint256",
          "name": "postId",
          "type": "uint256"
        },
        {
          "internalType": "string",
          "name": "postText",
          "type": "string"
        }
      ],
      "name": "editPost",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function",
      "payable": true
    }],
    functionName: 'editPost',
    value: getSumFee().toString(),
    enabled: (!isLoadingFees && !isConnecting),
    args: [        
      smartEditToken,
      postStatus
    ],
    onError(error) {
      notification
      .error({message: "Error: " + error.code + " " + error.message, duration: 10, placement:'topLeft'})
    },
    onSuccess(data) {
      notification
      .success({message:  'NFT was submitted to the blockchain (hash: ). '+data['hash']+').', duration: 2, placement:'topLeft'})
      notification
      .info({message:  'Website will be updated when block is approved.', duration: 5, placement:'topLeft'})
    }
    })
  
  const post = async () => {    
    notification
    .info({message: 'Minting of post NFT submitted to your crypto wallet.', duration: 5, placement:'topLeft'})

    if (smartEditToken==0) {
      submitPost()
    } else {
      editPost()
    }
  }

  const onReplyFacePressed = async () => {
    const balance = getBalances()
    if (balance === 0) {
        notification
        .error({message:  'Your wallet is empty, you need some token to pay for micro-payments.', duration: 8, placement:'topLeft'})
      } else { 
        setWaiting(true); 
        
        post()

        if (props.parentUpdate !== undefined) {
          props.parentUpdate();        
        }
        setWaiting(false);                                                            
        props.setIsModalVisible(false);

      }
    };

  const switchSubPanel = (subPanelGoal) => {
    if (subPanel === subPanelGoal) {
      setsubPanel("")
    } else {
      setsubPanel(subPanelGoal)
    }
  }

  return (
    <>
    <Modal
        open={props.isModalVisible}
        footer={null}
        style={{ top: 10, overflowX: "hidden" }}
        onCancel={() => props.setIsModalVisible(false)}
        bodyStyle={{
        padding: "15px",
        }}
        width={isBrowser?"600px":"450px"}
    >
        <div className="Poster">
          <form>
              <div className="ReplyPicture">
                {(smartreplyNFTContract!=="0x0000000000000000000000000000000000000000" && smartreplyToken!==0)?
                    smartreplyNFTContract.toLowerCase() === process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()?
                      <div className="modalQuotedPost">
                        <SinglePost mini_mode={true} allowExpansion={false} seedPost={true} showPostThreadLine={true} postid={smartreplyToken} setStripRepliersNb={()=>{}}/>
                      </div>
                      :
                      <NFTPic size={300} 
                        smartcontract={smartreplyNFTContract}
                        tokenid={smartreplyToken}
                        key={smartreplyNFTContract+smartreplyToken} 
                      />
                    :
                    ""
                }
              </div>
              {(!isConnecting && isConnected)?
                <WalletThumb walletAddress={address} />
                :
                ""
              }
              <div className="post_status_text">
                <textarea  ref={InputRef}
                    placeholder="Share your thoughts..."
                    disabled={waiting}
                    value={postStatus}
                    onChange={(event) => {setPostStatus(event.target.value)
                      InputRef.current.style.height = InputRef.current.scrollHeight+'px';
                    }} />
              </div>
              <div className="PosterPicture">
                {(smartAttachNFTContract!=="0x0000000000000000000000000000000000000000" && smartAttachToken!==0)?
                    smartAttachNFTContract.toLowerCase() === process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()?
                      <div className="modalQuotedPost">
                        <SinglePost mini_mode={true} showPostThreadLine={false} postid={smartAttachToken} setStripRepliersNb={()=>{}}/>
                      </div>
                      : 
                      tmpNFT?
                      <div className="tmp_image_holder">                  
                        <div className="post_final_image"><img src={tmpNFT} alt="webcam" style={{maxWidth:"300px"}}/></div>
                      </div>
                      :
                      <NFTPic size={300} 
                        smartcontract={smartAttachNFTContract}
                        tokenid={smartAttachToken}
                        key={smartAttachNFTContract+smartAttachToken} 
                      />
                    :
                  tmpNFT?
                  <div className="tmp_image_holder">                  
                    <div className="post_tmp_image"><img src={tmpNFT} alt="webcam" style={{maxWidth:"300px"}} /></div>
                    <div className="overlay_loading"><ClipLoader size={50} color="#2BCDE4" /></div>
                    <div className="overlay_minting">{smartEditToken!=0?"Editing":"Minting"}</div>
                  </div>
                  :
                  ""
                }
              </div> 
          </form>
          {smartEditToken==0?
            <div className="PosterTools">
                  <div className="ProfileNFT" style={{width:"30px"}} onClick={() => switchSubPanel("NFTWallet")}>
                    <FaWallet size="30px" color={"NFTWallet" === subPanel?"black":""} className="ProfileNFTIcon" />
                  </div>
                  <div className="CameraNFT" style={{width:"30px"}}  onClick={() => {switchSubPanel("NFTCamera")}} >
                    <AiFillCamera size="32px" color={"NFTCamera" === subPanel?"black":""}  className="CameraNFTIcon" />
                  </div>
                  <div className="emojiIcon"  onClick={() => {switchSubPanel("Emojis")}} >
                      <BsFillEmojiWinkFill color={"Emojis" === subPanel?"black":""}  size="27px" />
                  </div>  
                  <div className="UploadNFTIcon" style={{width:"30px"}} onClick={() => {switchSubPanel("NFTUpload")}} >
                      <ImFolderUpload color={"NFTUpload" === subPanel?"black":""}  size="32px" />
                  </div>
                  <div className="AttachNFT" style={{width:"30px"}} onClick={() => {switchSubPanel("AttachNFT")}} >
                    <ImAttachment color={"AttachNFT" === subPanel?"black":""} size="30px" className="AttachNFTIcon" />
                  </div>
            </div>
          :
          ""
          }
          <DisplayTips smartreplyNFTContract={smartreplyNFTContract} smartreplyToken={smartreplyToken}
                        smartAttachNFTContract={smartAttachNFTContract} smartAttachToken={smartAttachToken}
          />
          <div className="PostStatusInputSubmit">
            <button className="PostStatusInputSubmitButton" disabled={waiting} onClick={() => 
                                                            onReplyFacePressed()
                                                        }>
                {(waiting)?<>
                            <ClipLoader size="20px"/> ...
                            </>:
                                smartEditToken!=0?
                                "Edit Post"
                                :
                                "Mint Post"
                }
            </button>
          </div>
            {"NFTWallet" === subPanel?
              <>
                <Divider plain>Select one of your NFTs</Divider>          
                <div className="OptionalSubPanel OptionalSubPanelGallery" ref={subpanel_container}>
                  <YourFaces verticalContainer={subpanel_container} 
                    showProfile={props.showProfile} 
                    setIsModalVisible={()=>{switchSubPanel("")}} 
                    showTranfer={false} 
                    setContractToken={setAttachContractToken}
                    setContract={setAttachContract} />          
                </div>
              </>
              :""}
            {"NFTCamera" === subPanel?
              <>
                <Divider plain>Convert a camera picture into an NFT</Divider>          
                <div className="OptionalSubPanel" >
                  <PostCameraNFT 
                    settmpImg={settmpNFT} 
                    setContractToken={setAttachContractToken} 
                    setContract={setAttachContract} 
                    setIsModalVisible={()=>{switchSubPanel("")}} 
                  />     
                </div>
              </>
              :""}
            {"Emojis" === subPanel?
              <>
                <Divider plain>Add emojis to your post</Divider>          
                <div className="OptionalSubPanel" >
                  <PostEmojis 
                      currentText={postStatus} 
                      editText={setPostStatus} 
                  />
                </div>
              </>
              :""}
            {"NFTUpload" === subPanel?
              <>
                <Divider plain>Upload an image to create an NFT</Divider>          
                <div className="OptionalSubPanel" >
                  <PostUpload 
                      settmpImg={settmpNFT} 
                      setContractToken={setAttachContractToken} 
                      setContract={setAttachContract} 
                      setIsModalVisible={()=>{switchSubPanel("")}} 
                      />
                </div>
              </>
              :""}
            {"AttachNFT" === subPanel?
              <>
                <Divider plain>Share an external NFT</Divider>          
                <div className="OptionalSubPanel" >
                  <PostAttachNFT 
                      settmpImg={settmpNFT} 
                      setContractToken={setAttachContractToken} 
                      setContract={setAttachContract} 
                      setIsModalVisible={()=>{switchSubPanel("")}} 
                  />
                </div>
              </>
              :""}
        </div>
    </Modal>
    </>
    )
}

if (isBrowser) {
  ModalPost.defaultProps = {
    img_size: 200,
    smartReplyNFTContract : "0x0000000000000000000000000000000000000000",
    smartAttachNFTContract : "0x0000000000000000000000000000000000000000",
    smartReplyToken: 0,
    smartEditToken: 0,
    smartAttachToken: 0,
    postStatus: ""
  }
} else {
  ModalPost.defaultProps = {
    img_size: 175,
    smartReplyNFTContract : "0x0000000000000000000000000000000000000000",
    smartAttachNFTContract : "0x0000000000000000000000000000000000000000",
    smartReplyToken: 0,
    smartEditToken: 0,
    smartAttachToken: 0, 
    postStatus: ""
  }
}

export default ModalPost;