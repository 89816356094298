import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import Replyer from './replyer'
import {BiBookAdd, BiBookOpen} from "react-icons/bi";
import "./single_post_replies.css"
import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const SinglePostReplies = (props) => {
  const [data, setdata] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {      
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','PostMinted'),
      where("replyNFTTokenId", '==',props.postid), 
      where("replyNFTSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    return () => unsubscribe();
  }, [props.postid]);

  const createExpandIcon = (isexpanded, nbreplies) => {
    if (nbreplies>0) {
        if (isexpanded) {
            return (<>
                      <BiBookOpen  className="ExpandPostButton" size="25px"/>
                    </>
            )
        } else {
            return (<>
                      <BiBookAdd className="ExpandPostButton" size="25px"/>
                    </>
            )              
        }
    } else {
        return ""
    }
  }        

if (!isLoading) {
        return (
          <>
            <div className="bubbleNbReplies">  
                <Replyer readOnly={props.readOnly} postid={props.postid} />
                <div className="repliesNb">                
                  <Link to={"/post/"+props.postid} > 
                    {(data.length).toString()}
                  </Link>
                </div>
            </div>                
            {(props.allowExpansion)?(
                <div className="StripExpansionButton" onClick={props.stripExpansionCallback}>
                    {createExpandIcon(props.StripRepliersNb>0, data.length)}
                </div>
                    ):""}
          </>
            )
  }
  else {
        return (
            <div className="bubbleNbReplies">
                <ClipLoader size={20} color="#2BCDE4" />
            </div>
        )
  }
}

SinglePostReplies.defaultProps = {
  readOnly: false
}
export default SinglePostReplies;