import { useState } from "react";
import { notification } from 'antd';
import {
  Input,
  Button,
} from 'antd';
import ModalPostGalleryNFT from '../components/modalPost_gallery_nft'
import './modalProfPicture.css';
import NFTPic from './nft_pic'
import empty_profile from '../components/blank-profile-picture-large.png';
import { useAccount, useBalance, useContractRead, useContractWrite } from 'wagmi'
import { useQuery } from 'react-query'
import {
    query,
    collection,
    where,
    limit,
    orderBy,
    getDocs
  } from "firebase/firestore";
  import {db} from '../components/firestore_db';

const ModalProfPicture = (props) => {
  const { address } = useAccount()
  const { refetch } = useBalance({
    address: address,
    enabled: false
  })

  const [ProfContract, setProfContract] = useState("");
  const [ProfContractToken, setProfContractToken] = useState("");
  const [waiting, setWaiting] = useState(false);

  const fetchProfile = async () => {
      const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','ProfilePicturePosted'), 
      where("Author", '==', address.toLowerCase()), 
      orderBy("blockNumber", "desc"),
      limit(1));

      const results = await getDocs(query_data);
      var firstItem = []
      if (results.docs[0]) {
          firstItem = results.docs[0].data();                
      }
      return firstItem
  }

const { data:dataProfile } = useQuery(['ProfilePicturePosted',
                                    address
                                    ], 
                                    () => fetchProfile()
                                )

  const { data:dataFee, isFetched } = useContractRead(
    {
      address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
      abi: [{
        "inputs": [],
        "name": "profile_fee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
      }],
      functionName: 'profile_fee'            
  })

var strFees = "0"
if (isFetched && dataFee) {
  strFees = dataFee.toString()
}

const { write:submitProfile } = useContractWrite({
  address: process.env.REACT_APP_POLYGON_CONTRACT,
  abi: [{
    "inputs": [
      {
        "internalType": "address",
        "name": "profileSmartContract",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "profileNFTTokenId",
        "type": "uint256"
      }
    ],
    "name": "setProfilePicture",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }],
  functionName: 'setProfilePicture',
  value: strFees,
  enabled: (isFetched && dataFee && ProfContract !== ""),
  args: [        
      ProfContract,
      ProfContractToken
  ],
  onSubmitPressed(data) {
    notification
    .info({message: 'Transaction for status picture submitted to your crypto wallet.', duration: 8, placement:'topLeft'})
  },
  onError(error) {
    notification
    .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
  },
  onSuccess(data) {
    notification
    .success({message:  'Status picture was submitted to the blockchain (hash: '+data['hash']+').', duration: 2, placement:'topLeft'})
    notification
    .info({message:  'Website will be updated when block is approved.', duration: 5, placement:'topLeft'})
  }
  })

  const postPictStatus = async () => {

    if (ProfContract === "") {
      notification
      .error({message: 'Please select an NFT in your wallet first.', duration: 8, placement:'topLeft'})

    } else {
      submitProfile()
    }
  }

  const onSubmitPressed = async () => {
    const balance = await refetch()
    if (parseFloat(balance['formatted']) === 0) {
        notification
        .error({message: 'Your wallet is empty, you need some token to pay for micro-payments.', duration: 8, placement:'topLeft'})

      } else { 
        setWaiting(true); 

        await postPictStatus();

        setWaiting(false);    
    }
  };

  if (dataProfile !== undefined) {
    const local_contract = dataProfile['NFTprofileSmartContract']
    const local_token = dataProfile['tokenId']
    if (ProfContract === "") {
      setProfContractToken(local_token)
      setProfContract(local_contract)
    }
  }

return (
    <>  
        <div className="StatusProfPoster">
          <div className="ProfilePoster">  
            <ModalPostGalleryNFT showProfile={false} setContractToken={setProfContractToken} setContract={setProfContract} modalPostwaitingcallback={setWaiting} />
          </div>        
          {ProfContract!==""?
            <div className="PreviewContract">  
                <NFTPic key={ProfContract+ProfContractToken} size={100} 
                        smartcontract={ProfContract}
                        tokenid={ProfContractToken}
                />
            </div>
          :
          <div className="PreviewContract">  
              <img width={100} alt={"Profile"} src={empty_profile} />
          </div>
          }
          <Input.Group compact>
            <Button className="PostStatusInputSubmitButton" type="primary" loading={waiting} onClick={() => {
              if (!waiting) {
                onSubmitPressed()
              }
              }}>
              Mint new profile picture
            </Button>
          </Input.Group>
        </div>
    </>
    )
}

export default ModalProfPicture;