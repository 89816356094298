import ChainsIn from "./chainsIn"
import RepliesIn from "./repliesIn"
import MaticToSmallChains from "./maticTosmallChains"
import MaticFromSmallChains from "./maticFromsmallChains"

const YourStats = (props) => {
    
    return (
        <>
            <div className="TipsProfile">
                <ChainsIn walletAddress={props.walletAddress} />
            </div>
            <div className="TipsProfile">
                <RepliesIn walletAddress={props.walletAddress} />
            </div>
            <div className="TipsProfile">Total MATIC tips sent to users: 
                {" "}<MaticToSmallChains walletAddress={props.walletAddress} />
            </div>
            <div className="TipsProfile">Total MATIC tips received from users: 
                {" "}<MaticFromSmallChains walletAddress={props.walletAddress} />
            </div>
        </>
    )
  }

export default YourStats;