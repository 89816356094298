import { Popover } from 'antd';
import WalletCard from "./wallet_card";
import "./single_post_wallet.css"
import { isBrowser } from 'react-device-detect';
import { useQuery } from 'react-query'
import {
    getDocs,
    query,
    collection,
    where,
    orderBy
  } from "firebase/firestore";
  import {db} from '../components/firestore_db';

const SinglePostWallet = (props) => {
    const  fetch  = async () => {
            const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
                where("name", '==','ProfileStatusPosted'), 
                where("Author", '==',props.walletAddress.toLowerCase()),
                orderBy("blockNumber", "desc")
            );
            const querySnapshot = await getDocs(q);
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
                }));
            if (newData === undefined || newData.length===0) {
                return ""
            } else {
                return newData[0]['status']
            }
        }

    const { data: dataCloud } = useQuery(['getUsername',
                                        props.walletAddress.toLowerCase()
                                        ], 
                                        async () => {var username = await fetch()
                                            if (username)
                                            { 
                                                return username
                                            } 
                                            else 
                                            {
                                                return undefined
                                            }
                                            }
                                        )

    if (dataCloud) {
        return (
            <div className="bubbleid">
                <Popover trigger={isBrowser?"hover":"click"} overlayInnerStyle={{borderRadius: '5px', border:'1px solid black'}} content={<div className="WallletPopup"><WalletCard walletAddress={props.walletAddress} /></div>}>
                        {dataCloud?dataCloud:props.walletAddress.substring(0, 3)+".."+props.walletAddress.substring(props.walletAddress.length-3)} 
                </Popover>
            </div>  
            )
    } else {
        return (
            <div className="bubbleid">
                <Popover trigger={isBrowser?"hover":"click"} overlayInnerStyle={{borderRadius: '5px', border:'1px solid black'}} content={<div className="WallletPopup"><WalletCard walletAddress={props.walletAddress} /></div>}>
                        {props.walletAddress.substring(0, 3)+".."+props.walletAddress.substring(props.walletAddress.length-3)} 
                </Popover>
            </div>  
            )
    }
}

export default SinglePostWallet;