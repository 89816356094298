import { toArray } from "react-emoji-render";
import './single_post_bubble.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import urlParser from "js-video-url-parser";
import ReactPlayer from 'react-player/lazy'
import { getFunctions, httpsCallable } from "firebase/functions";

const SinglePostBubble = (props) => {

    const parseEmojis = value => {
        const emojisArray = toArray(value);

        // toArray outputs React elements for emojis and strings for other
        const newValue = emojisArray.reduce((previous, current) => {
            if (typeof current === "string") {
            return previous + current;
            }
            return previous + current.props.children;
        }, "");

        return newValue;
    };
  
    const extractTwitter = (link_text) => {
        var parse = require('twitter-url-parser');
        var result = parse(link_text);
        return result
    }
  
    const extractLinkify = async (url) => {
        const functions = getFunctions();
        const linkprettify = httpsCallable(functions, 'linkprettify');
        const res = await linkprettify({ url: url })
        return res.data
    }
    
    const videoUrlParser = (url) => {
        var video_parsing = urlParser.parse(url)
        return video_parsing
      }

    const extractLinks = (text) => {
        var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        var urls = text.match(urlRegex)
        var shortentext =  text.replace(urlRegex, function(url) {
            return "";
        });

        return [urls, shortentext] 
    }

    var bubble_status = ""

     try {
        const bubble_content = JSON.parse(props.status);
        if ('txt' in bubble_content) {
            bubble_status = bubble_content['txt']
        } else {
            bubble_status = props.status
        }
    } catch (e) {
        bubble_status = props.status
    } 

    var allLinks = extractLinks(bubble_status)
    var reduced_text = allLinks[1]
    var links_array = allLinks[0]

    var twitter_id 
    var youtube_id
    var video_link

    if (links_array) {
        twitter_id = extractTwitter(links_array[0]) 
        var video_parse = videoUrlParser(links_array[0]) 
        if (video_parse) {
            if (['vimeo', 'twitch', 'dailymotion', 'soundcloud', 'wistia', 'facebook'].includes(video_parse['provider']))
                { 
                    video_link = links_array[0]
                } else if (['youtube'].includes(video_parse['provider']))
                {
                    youtube_id = video_parse['id']
                }
        }
    }

    if (props.mini_mode & reduced_text.length>100) {
        reduced_text = reduced_text.substring(0, 100) + " ..."
    }

    return (
        <>
        <div className="placeholderBubble">
            <div className="post_text_style" >
                {parseEmojis(reduced_text)}
            </div>
        </div>   
        {video_link?<div className="LinkPreview">
                        <ReactPlayer url={video_link} 
                        controls = {true}
                        width = {"100%"}
                        height = {300}
                        />
                    </div>:""
        }
        {youtube_id?<div className="LinkPreview">
            <iframe
                width="100%"
                height="300px"
                src={`https://www.youtube.com/embed/${youtube_id}`}
                style={{border:"0px"}}
                allowFullScreen
                title="Embedded youtube"
                />
            </div>:""}
        {twitter_id?<div className="LinkPreview"><TwitterTweetEmbed tweetId={twitter_id['id']} /></div>:""}
        {!video_link && !youtube_id && !twitter_id && links_array && !props.mini_mode?<div className="LinkPreview"><LinkPreview fetcher={extractLinkify} url={links_array[0]} fallback={<a href={links_array[0]}>{links_array[0]}</a>} showPlaceholderIfNoImage={true} width={"100%"} /></div>:""}
        </>
        )
    }

  
SinglePostBubble.defaultProps = {
    status: "",
    mini_mode: false
}

export default SinglePostBubble;