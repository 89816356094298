import { useParams } from 'react-router-dom';
import Strip from '../components/strip'  
import { useEffect } from "react";

const Post = (props) => {
  const { postid } = useParams();

  useEffect(() => {
    document.title = "Post "+postid+"  "+process.env.REACT_APP_WEBSITE_TITLE;  
    }, [postid]);

  return (
        <Strip key={postid} postid={postid} />
        )
  }

export default Post;