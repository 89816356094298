import SinglePost from "./single_post"
import {useRef} from "react";

const Strip = (props) => {
    const sliderRef = useRef(null);

    const emptycallback = () => {
    }

   const styles_empty_div = {
      width: "0px",
    }

   // This is necessary to allow to switch between tree branches without jumping scrolling position
   if (sliderRef.current!==null) {
      styles_empty_div.width = (sliderRef.current.clientWidth-200)+"px"
   }

    return (
          <div className="StripContainer">
            <div className="AllFaces" ref={sliderRef}>
              <SinglePost allowExpansion={props.allowExpansion} showPostThreadLine={props.showPostThreadLine} showPostTime={props.showPostTime} readOnly={props.readOnly} seedPost={true} StripRepliersNb={Infinity} postid={props.postid} setStripRepliersNb={emptycallback}/>
              <div className="EmptyWidth" style={styles_empty_div}> </div>
            </div>
          </div>
          )
  }

  Strip.defaultProps = {
    readOnly:false,
    showPostTime:true,
    allowExpansion:true
  }
export default Strip;