import './wallet_thumb.css';
import empty_profile from '../components/blank-profile-picture-thumb.png';
import NFTPic from './nft_pic'
import { useQuery } from 'react-query'
import {
    query,
    collection,
    where,
    limit,
    getDocs,
    orderBy
  } from "firebase/firestore";
import {db} from '../components/firestore_db';

const WalletThumb = (props) => {
    const fetch = async () => {
        if (props.walletAddress) {
            const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
            where("name", '==','ProfilePicturePosted'), 
            where("Author", '==', props.walletAddress.toLowerCase()), 
            orderBy("blockNumber", "desc"),
            limit(1));

            const results = await getDocs(query_data);
            var firstItem = []
            if (results.docs[0]) {
                firstItem = results.docs[0].data();                
            }
            return firstItem
        } else {
            return []
        }
    }

    const { data, isLoading } = useQuery(['ProfilePicturePosted',
                                        props.walletAddress.toLowerCase()
                                        ], 
                                        () => fetch()
                                    )

    const WalletThumbStyle = {
       width: props.size+1+"px",
       height: props.size+1+"px",
    }
    
    if (!isLoading && data !== null && data !== undefined  && data.hasOwnProperty('NFTprofileSmartContract')) {
        return (
            <div className="WalletThumb" style={WalletThumbStyle} >
                <NFTPic size={props.size} 
                    key={1} 
                    smartcontract={data['NFTprofileSmartContract']}
                    tokenid={data['tokenId']}
                />
            </div> 
        )
    }
    else {
        return (
            <div className="WalletThumb" style={WalletThumbStyle} >
                <img width={props.size+1} alt={"Profile"} src={empty_profile} />
            </div>
        )
    }
}

WalletThumb.defaultProps = {
    size: 38
  }

export default WalletThumb;