import { useState } from "react";
import { isBrowser } from 'react-device-detect';
import React from "react";
import './nft_pic.css';
import { useQuery } from 'react-query'
import {SendOutlined} from "@ant-design/icons";
import { Modal } from 'antd';
import AddressInput from "./AddressInput";
import {BsPersonCheckFill} from 'react-icons/bs';
import ReactCardFlip from 'react-card-flip';
import SinglePostWallet from '../components/single_post_wallet'
import opensealogo from '../components/opensea.svg';
import polygonscanlogo from '../components/polygonscan.svg';
import jsonlogo from '../components/logo-json.png';
import {MdOutlineFlipCameraAndroid} from 'react-icons/md';
import { useAccount, useContractReads, useContractWrite } from 'wagmi'
import { notification } from 'antd';
import { getFunctions, httpsCallable } from "firebase/functions";
import {storage} from '../components/firestore_db';
import { ref, getDownloadURL } from "firebase/storage";
import unavailable from '../components/unavailable.png';

const NFTPic = (props) => {
    const [nftToSend, setNftToSend] = useState(undefined);
    const [visible, setVisibility] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [receiverToSend, setReceiver] = useState(undefined);
    const [isflipped, setFlip] = useState(false);
    const { address } = useAccount()

    const handleClick = (e) => {
      e.preventDefault();
      setFlip(!isflipped);
    }

    const { refetch:fetchURLNFT, isFetched } = useContractReads({
      contracts: [
        {
          address: props.smartcontract?.toLowerCase(),
          args: [props.tokenid],
          abi: [
            {
                "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
                ],
                "name": "tokenURI",
                "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
                ],
                "stateMutability": "view",
                "type": "function",
                "constant": true
            }
          ],
          functionName: "tokenURI"
        },
        {
          address: props.smartcontract?.toLowerCase(),
          abi: [{
            "inputs": [],
            "name": "name",
            "outputs": [
              {
                "internalType": "string",
                "name": "",
                "type": "string"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          }],
          functionName: 'name',
        },
        {
          address: props.smartcontract?.toLowerCase(),
          abi: [{
            "inputs": [],
            "name": "symbol",
            "outputs": [
              {
                "internalType": "string",
                "name": "",
                "type": "string"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          }],
          functionName: 'symbol',
        },
        {
          address: props.smartcontract?.toLowerCase(),
          args: [props.tokenid],
          abi: [{
            "inputs": [
              {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
              }
            ],
            "name": "ownerOf",
            "outputs": [
              {
                "internalType": "address",
                "name": "",
                "type": "address"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          }],
          functionName: 'ownerOf',
        }              
      ], enabled: false
    })

    // This is to allow caching of NFT url
    const { isLoading:isTokenMetaLoading, data: tokenIdMetadata } = useQuery(['getNFTURL',
                                        props.smartcontract?.toLowerCase(),
                                        props.tokenid
                                        ], 
                                        () => fetchURLNFT())

    const styles = {
      loader: {
        paddingTop: "0px",
        paddingLeft: "0px",
        width: props.size/2+"px",
        heigth: props.size/2+"px"
      },
      imgprofile: {
        maxWidth:props.size+"px",
        maxHeight:props.size+"px",
        overflow: "hidden"
      }
    };
    
    const handleTransferClick = (nftcontract, nfttoken) => {
      setNftToSend([nftcontract, nfttoken]);
      setVisibility(true);
    };

     const { write } = useContractWrite({
      address: props.smartcontract?.toLowerCase(),
      abi: [{
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      }]
      ,
      functionName: 'transferFrom',
      enabled: receiverToSend !== undefined,
      args: [        
        address,
        receiverToSend,
        nftToSend?.[1] || ""
      ],
      onError(error) {
        notification
        .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
      },
      onSuccess(data) {
        notification
        .success({message:  'Transfer was submitted to the blockchain (hash: ). '+data['hash']+').', duration: 2, placement:'topLeft'})
      },
      enabled: receiverToSend !== undefined
      })

    async function transfer(nftcontract, tokenid, receiver) {

      setIsPending(true);
 
      await write({
        args: [        
          address,
          receiver,
          tokenid
        ],
      }
      ) 
      setVisibility(false)
      setIsPending(false);
    }

    if (isFetched && !isTokenMetaLoading) {
      var json_path = tokenIdMetadata['data'][0].result
      var name = tokenIdMetadata['data'][1].result
      var symb = tokenIdMetadata['data'][2].result
      var owner = tokenIdMetadata['data'][3].result
    }

    function resizeImage(width, height, maxWidth, maxHeight) {
      // Calculate the aspect ratio of the image
      const aspectRatio = width / height;
    
      // If the width and height are within the maximum limits, return them as is
      if (width <= maxWidth && height <= maxHeight) {
        return { width: Math.round(width), height: Math.round(height) };
      }
    
      // Determine which dimension (width or height) needs to be scaled down more
      const widthRatio = maxWidth / width;
      const heightRatio = maxHeight / height;
      let targetWidth, targetHeight;
    
      if (widthRatio < heightRatio) {
        // Scale down the width
        targetWidth = maxWidth;
        targetHeight = targetWidth / aspectRatio;
      } else {
        // Scale down the height
        targetHeight = maxHeight;
        targetWidth = targetHeight * aspectRatio;
      }
      return { width: Math.round(targetWidth), height: Math.round(targetHeight) };
    }

    async function fetchData() {
        // the json path needs to be workable
        if (json_path.includes("://")) {  
            // This is when we have a valid URI       

            const functions = getFunctions();
            const getnftjson = httpsCallable(functions, 'getnftjson');
            var json_content = await getnftjson({ json_path: json_path })
            json_content = json_content.data

            if (!json_content.hasOwnProperty('image_cache_width')) {
              json_content.image_cache_width = undefined
              json_content.image_cache_height = undefined
            } else {
              const newsize = resizeImage(json_content.image_cache_width, json_content.image_cache_height, props.size, props.size)
              json_content.image_cache_width = newsize.width
              json_content.image_cache_height = newsize.height
            }

            if (json_content.hasOwnProperty('image_cache')) {
              const starsRef = ref(storage, json_content.image_cache);
              const url = await getDownloadURL(starsRef)
              return {url: url, width: json_content.image_cache_width, height: json_content.image_cache_height};
            } else if (json_content.hasOwnProperty('image')) {
                var local_image_path = json_content.image
                return {url: local_image_path, width: json_content.image_cache_width, height: json_content.image_cache_height};
            } 
        } else {
          const newsize = resizeImage(200, 200, props.size, props.size)
          return {url: unavailable, width: newsize.width, height: newsize.height};
        }
      }

    // This is to allow caching of NFT json
    const { isLoading:isJSONLoading, data: JSONdata } = useQuery(['getNFTJSON',
                                        json_path,
                                        props.size
                                        ], 
                                        () => fetchData(),
                                        {
                                          enabled: isFetched && !isTokenMetaLoading,
                                        }
                                    )   
    if (!JSONdata) {
      return (<div className="NFT_container">
            
              </div>)
    } else {
        return (
          <div className="NFT_container">
            {props.show_info?   
            <>           
              <ReactCardFlip cardZIndex={100}  isFlipped={isflipped} flipDirection="vertical">
                  <div className="front_card" onClick={handleClick} >
                    <div className="ImgProfile">    
                      <div className="flip_button">
                        NFT
                      </div>
                      <img src={JSONdata.url} width={JSONdata.width} height={JSONdata.height} alt="profile" style={styles.imgprofile}/>
                    </div>
                  </div>
                  <div className="back_card">
                    <div className="ImgProfile ImgFaded">
                      <div className="flip_button" onClick={handleClick} >
                        <MdOutlineFlipCameraAndroid size={17} />
                      </div>
                      <img src={JSONdata.url} width={JSONdata.width} height={JSONdata.height} alt="profile" style={styles.imgprofile}/>
                    </div>
                      <div className="nft_name">
                        <div className="nft_info">
                          <div className="single_info">
                            {props.show_profile?<div className="CheckedFace"><BsPersonCheckFill size="20px"/></div>:""}   
                              {props.show_send?
                                  <div className="transferbutton" onClick={() => handleTransferClick(json, props.tokenid)}>                                   
                                      <SendOutlined style={{ fontSize: '14px'}} />
                                  </div>
                                :
                                ""
                              }
                          </div>
                          <div className="single_info">
                          {name+" "}({symb})
                            #{props.tokenid.toString().length>5?props.tokenid.toString().substring(0, 5)+'...':props.tokenid} 
                          </div>
                          <div className="single_info"><a href={"https://opensea.io/assets/matic/"+props.smartcontract+"/"+props.tokenid} target="_blank" rel="noreferrer"><img src={opensealogo} alt="opensea" height="20px" />NFT on OpenSea</a></div>
                          <div className="single_info">
                            <a href={process.env.REACT_APP_POLYGON_EXPLORER+'address/'+props.smartcontract?.toLowerCase()} target="_blank" rel="noreferrer"><img src={polygonscanlogo} alt="polygonscan" width="100px" /> 
                            SmartContract on Polygon
                            </a>
                          </div>
                          <div className="single_info"><a href={json_path} rel="noreferrer" ><img src={jsonlogo} alt="json" height="30px" />NFT json data</a></div>
                          {!isFetched && owner?<div className="single_info">Owned by: { <SinglePostWallet walletAddress={owner} /> }</div>:""}
                          
                        </div>
                      </div>    
                  </div>
              </ReactCardFlip>
              <Modal
                    title={"Transfer NFT"}
                    open={visible}
                    onCancel={() => setVisibility(false)}
                    onOk={() => transfer(nftToSend[0], nftToSend[1], receiverToSend)}
                    confirmLoading={isPending}
                    okText="Send"
                  >
                <AddressInput autoFocus placeholder="Receiver" onChange={setReceiver} />
              </Modal>
            </> 
            :
            <div className="ImgProfile">    
              <img src={JSONdata.url} width={JSONdata.width} height={JSONdata.height} alt="profile" style={styles.imgprofile}/>
            </div>
            }
          </div>
        )
      }
}

if (isBrowser) {
  NFTPic.defaultProps = {
    size: 200,   
    show_info: false,
    show_send: false,
    show_profile: false
  }
} else {
  NFTPic.defaultProps = {
    size: 175, 
    show_info: false,
    show_send: false,
    show_profile: false
  }
}

export default NFTPic