import ProfileTipsIn from "./profileTipsIn"
import ProfileTipsOut from "./profileTipsOut"

const ProfileTips = (props) => {
    
    return (
    <div className="TipsProfile">Tips:
        <ProfileTipsIn walletAddress={props.walletAddress} />
        <ProfileTipsOut walletAddress={props.walletAddress} />
    </div>
    )
  }
  
  export default ProfileTips