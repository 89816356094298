import {IoMdNotifications} from "react-icons/io"
import { Badge } from 'antd';
import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const NotificationsButton = (props) => {
    const [data, setdata] = useState([]);

    useEffect(() => {
        const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
          where("name", '==','NFTTipped'), 
          where("receiver", '==',  props.walletAddress.toLowerCase()), 
          where("blockNumber", '>',  parseInt(props.blockNumber)), 
          where("NFTprofileSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setdata(newData);
          });
        return () => unsubscribe();
      }, [props.blockNumber, props.walletAddress]);
    
  return (
        <Badge key={data.length} count={data.length} color="#FF5C56">
            <IoMdNotifications className="NotifyButton" size={props.size} />     
        </Badge>
  )
}

NotificationsButton.defaultProps = {
    walletAddress: "",
  }
  
export default NotificationsButton;