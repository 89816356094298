import './modalLogin.css';
import {BiLogInCircle} from "react-icons/bi"
import {
  useConnectModal,
} from '@rainbow-me/rainbowkit';


const ModalLogin = (props) => {
  const { openConnectModal } = useConnectModal();
  
  return (
    <>
    <BiLogInCircle size={props.size} onClick={
                    () => openConnectModal()
                    } />
    </>
    )
}
ModalLogin.defaultProps = {
  size: "30px",
}

export default ModalLogin;