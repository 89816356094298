import ClipLoader from "react-spinners/ClipLoader";
import './home.css';
import ListStrips from '../components/list_strips'  
import {db} from '../components/firestore_db';
import { useState, useEffect } from 'react';
import {
  query,
  collection,
  onSnapshot,
  where, 
  orderBy
} from "firebase/firestore";

const Home = (props) => {

  const [value, setvalue] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','PostMinted'), 
      where("replyNFTSmartContract", '==', "0x0000000000000000000000000000000000000000") ,
      orderBy("blockNumber") 
      );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setvalue(newData);
        setLoading(false);
      });
    return () => unsubscribe();
  }, []);

    useEffect(() => {
      document.title = "Home  "+process.env.REACT_APP_WEBSITE_TITLE;  
    }, []);
    
    const only_keep_ids = (database_data) => {
      var _foundstrips = []
      for (var counter = database_data.length - 1; counter >= 0; counter--) {
          _foundstrips.push(database_data[counter]["PostId"])
        }
      return _foundstrips
    }

  if (value !== undefined && !loading) {
    return (
          <ListStrips key={String(only_keep_ids(value))} list_post_ids={only_keep_ids(value)} />
        )    
      } else {
        return (
          <div className="WaitingObject">
            Fetching blockchain data<br/>
            <ClipLoader size={25} color="#2081E2" />
          </div>
          )
    }
  }
export default Home;
