import Editer from './editer'
import "./single_post_edit.css"

const SinglePostEdit = (props) => {

    return (
        <>
            <div className="bubbleEdit">  
                <Editer postStatus={props.postStatus} readOnly={props.readOnly} postid={props.postid} /> 
            </div>                
        </>
        )
}
export default SinglePostEdit;