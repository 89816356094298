import ProfileTips from "./profileTips";
import './wallet_card.css';
import ProfileStatus from './profileStatus'
import { Link } from "react-router-dom";
import empty_profile from '../components/blank-profile-picture-large.png';
import NFTPic from './nft_pic'
import { useQuery } from 'react-query'
import {
    query,
    collection,
    where,
    limit,
    orderBy,
    getDocs
  } from "firebase/firestore";
import {db} from '../components/firestore_db';
// import { fetchEnsName } from '@wagmi/core'

const WalletCard = (props) => {
    // This is to allow caching of NFT metadata
/*     const fetch = async () => {
        var ens_result = await fetchEnsName({address: props.walletAddress, chainId:1})
        var return_ens = undefined;
        console.log(ens_result)
        console.log(props.walletAddress)

        if (ens_result.toLowerCase() !== props.walletAddress.toLowerCase())
            {
            return_ens = ens_result
            }
        return return_ens
    }

    const { data: ensName } = useQuery(['getUsername',
                                        props.walletAddress.toLowerCase()
                                        ], 
                                      () => fetch()
                                    )  */
    const ensName = undefined
    const fetchProfile = async () => {
        const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
        where("name", '==','ProfilePicturePosted'), 
        where("Author", '==', props.walletAddress.toLowerCase()), 
        orderBy("blockNumber", "desc"),
        limit(1));

        const results = await getDocs(query_data);
        var firstItem = []
        if (results.docs[0]) {
            firstItem = results.docs[0].data();                
        }
        return firstItem
    }

    const { data, isLoading } = useQuery(['ProfilePicturePosted',
                                        props.walletAddress.toLowerCase()
                                        ], 
                                        () => fetchProfile()
                                    )
    
    const link_wallet = process.env.REACT_APP_POLYGON_EXPLORER+"address/"+props.walletAddress

    if (!isLoading && data !== null && data !== undefined && data.hasOwnProperty('NFTprofileSmartContract')) {
        return (
            <>
                <div className="WalletCard" >
                    <div className="PictureWallet">
                        <NFTPic size={100} 
                            key={props.walletAddress} 
                            smartcontract={data['NFTprofileSmartContract']}
                            tokenid={data['tokenId']}
                        />
                    </div>
                    <div className="DescriptionWallet">
                        <Link to={"/profile/"+props.walletAddress}>
                            {ensName?ensName:""}<br/>
                        </Link>
                        <a href={link_wallet}>{props.walletAddress.substring(0, 4)+"..."+props.walletAddress.substring(props.walletAddress.length-4)}</a>
                        
                        <ProfileTips walletAddress={props.walletAddress} />
                    </div>
                    {props.showStatus?
                        <div className="StatusWallet">
                            <ProfileStatus walletAddress={props.walletAddress} />
                        </div>
                    :
                        <></>
                    }
                </div> 
            </> 
        )
    }
    else {
        return (
            <>
            <div className="WalletCard" >
                <div className="PictureWallet">
                    <div className="ImgProfile">
                        <img width={100} alt={"Profile"} src={empty_profile} />
                    </div>
                </div>
                <div className="DescriptionWallet">
                        <Link to={"/profile/"+props.walletAddress}>
                            {ensName?ensName:""}<br/>
                        </Link>
                        <a href={link_wallet}>{props.walletAddress.substring(0, 4)+"..."+props.walletAddress.substring(props.walletAddress.length-4)}</a>
                        
                        <ProfileTips walletAddress={props.walletAddress} />
                    </div>
                    {props.showStatus?
                        <div className="StatusWallet">
                            <ProfileStatus walletAddress={props.walletAddress} />
                        </div>
                    :
                        <></>
                    }
            </div> 
        </> 
        )
    }
}

WalletCard.defaultProps = {
    showStatus: true,
  }
  
export default WalletCard;