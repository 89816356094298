import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';
import { formatEther } from 'viem'

const MaticFromSmallChains = (props) => {
    const [data, setdata] = useState([]);

    useEffect(() => {
      const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
        where("name", '==','NFTTipped'), 
        where("receiver", '==',props.walletAddress.toLowerCase()), 
        where("giver", '!=', props.walletAddress.toLowerCase()));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const newData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setdata(newData);
        });
      return () => unsubscribe();
    }, [props.walletAddress]);

    var total_value = BigInt("0");

    for (const single_post of data) {
        total_value = total_value + BigInt(single_post['tipAmount'])
    }      
    const tokenValue = parseFloat(formatEther(total_value)).toFixed(4).toString()
    return (
    <>
        {tokenValue}
    </>
    )
  }

  export default MaticFromSmallChains