import './single_post_tree.css';

const SinglePostTree = (props) => {

    const getBranchStyle = () => {
        if (props.seedPost) {
            return {
                left:"-25px", 
                top:"15px",
                bottom:"0px"
            }
        } else if (props.openEnd) {
            return {
                left:"-25px",
                top:"0px",
                height:"17px"  
            }
        } else if (props.closeEnd) {
            return {
                left:"-25px", 
                top:"0px",
                height:"5px"  
            }
        } else {
            return {
                left:"-25px",
                top:"0px",
                bottom:"0px"    
            }
        }
    }

    const styleEnteringNode = {
        left:"-30px", 
        top:"-10px", 
    }

    const styleLeavingNode = {
        left:"-33px", 
        top:"10px", 
    }

    const styleEnteringBranch = {
        left:"-25px", 
        height:"-10px", 
    }

    const styleLeavingBranch = {
        left:"-25px", 
        top:"20px", 
    }

    const getNodeStyle = () => {
        if (props.seedPost) {
            return {
                top:"15px" ,
                height:"16px",
                width:"16px",
                left:"-32px",
            }
        } else if (props.openEnd) {
            return {
                top:"7px",
                height:"16px",
                width:"16px",
                left:"-32px",
                backgroundColor: "white",
                border: "2px solid",
                borderColor: "lightgray"
            }
        } else {
            return {
                top:"15px",
                height:"10px",
                width:"10px",
                left:"-30px",
            }
        }
    }
    if (props.openThread) {
        return (
            <>
                <div className="enteringBranch" style={styleEnteringBranch}></div>
                <div className="enteringNode" style={styleEnteringNode}></div>
                <div className="leavingNode" style={styleLeavingNode}></div>
                <div className="leavingBranch" style={styleLeavingBranch}></div>
            </>
        )
    } else {
        return (
            <>
                {props.singleNode?" ":<div className="postTreeBranch" style={getBranchStyle()}></div>}
                <div className="postTreeNode" style={getNodeStyle()}> </div>
            </>
        )
    }
}

SinglePostTree.defaultProps = {
  seedPost:false,
  openEnd:false,
  closeEnd:false,
  openThread:false,
  singleNode:false,
}

export default SinglePostTree;