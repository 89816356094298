import ClipLoader from "react-spinners/ClipLoader";
import { notification, Popover } from 'antd';
import {ImDiamonds} from "react-icons/im"
import "./single_post_tips.css"
import { isBrowser } from 'react-device-detect';
import WalletThumb from "./wallet_thumb"
import SinglePostWallet from '../components/single_post_wallet'
import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useQuery } from 'react-query'

const SinglePostTips = (props) => {
  const { isConnected } = useAccount()
  const [ data, setdata ] = useState([]);
  const [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','NFTTipped'), 
      where("tokenId", '==',props.postid), 
      where("NFTprofileSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    return () => unsubscribe();
  }, [props.postid]);


  const getLastTipsfromPost = () => {
    var all_tips = []
    for (let indiv of data) {
      var local_tip = {};
      local_tip['local_hash'] = indiv['transactionHash']
      local_tip['giver'] = indiv['giver']

      all_tips.push(local_tip)
    }

    return all_tips
   }; 

  const renderTipsList = () =>{
      const all_tips = getLastTipsfromPost()

      if (all_tips.length>0)
      {
        return (
            <>
              {all_tips.length} {all_tips.length>1?"tips:":"tip"}
              {
                  all_tips.map(function(local_tip, index){
                      return (
                          <div key={index} className="SingleTipHash">
                            <div className="TipThumb">
                              <WalletThumb walletAddress={local_tip['giver']} />
                            </div>
                            <div className="TipGiver">
                              <SinglePostWallet walletAddress={local_tip['giver']} />
                            </div>
                            <div className="TipHash">{'Hash: '} 
                              <a href={process.env.REACT_APP_POLYGON_EXPLORER+"tx/"+local_tip['local_hash']} target="_blank" rel="noreferrer" >
                                {local_tip['local_hash'].substring(1, 5)+"..."+local_tip['local_hash'].substring(local_tip['local_hash'].length-4)}
                              </a>
                            </div>
                          </div>
                      )
                  }
                  )
              }
            </>
            )
      } else {
        return ""
      }
    }


  const { refetch:refetchTip } = useContractRead(
      {
        address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        abi: [{
          "inputs": [],
          "name": "tipping_fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }],
        functionName: 'tipping_fee',            
        enabled: false
  })

  const { isLoading:isLoadingFee, data: tippingFee } = useQuery(['tipping_fee'], 
    () => refetchTip())

  const getTippingFee =  () => {
    var Fee = BigInt(0)
    if (!isLoadingFee) {
      Fee = tippingFee.data
    }  
    return Fee
  }

  
  const { write:tipToken } = useContractWrite({
      mode: 'recklesslyUnprepared',
      address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
      abi: [{
        "inputs": [
          {
            "internalType": "address",
            "name": "tokenSmartContract",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "tipToken",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
        }],
      functionName: 'tipToken',
      value: getTippingFee().toString(),
      args: [ 
        process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
        props.postid
        ],
      onError(error) {
        notification
        .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
      },
      onSuccess(data) {
        notification
        .success({message:  'Tip was submitted to the blockchain (hash: ). '+data['hash']+').', duration: 2, placement:'topLeft'})
        notification
        .info({message:  'Website will be updated when block is approved.', duration: 5, placement:'topLeft'})
      }
    })

  const onTippostPressed = async () => {
    await tipToken();
  };
  
if (!isLoading) {
        return (
          <>
            <div className={isConnected?"bubbleTip bubbleTipHover":"bubbleTip"} >
              {isConnected?
                <ImDiamonds className="heartBubble" style={{cursor: "pointer"}} size="20px" onClick={() => {onTippostPressed()}}/>
              :
                <ImDiamonds className="heartBubble" size="20px" />
              }            
                <div className="tiptext">
                  {data.length>0?
                  <Popover  trigger={isBrowser?"hover":"click"} overlayInnerStyle={{borderRadius: '5px', border:'1px solid black'}} content={renderTipsList()}>
                    {JSON.stringify(data.length)}
                  </Popover>
                  :
                  JSON.stringify(data.length)
                  }
              </div>
            </div>

        </>
            )
  }
  else {
        return (
            <div className="bubbleTip" >
               <ImDiamonds size="20px" /><ClipLoader size={18} color="#2BCDE4" />
            </div>
        )
  }
}

export default SinglePostTips;