import React from "react";
import {useDropzone} from 'react-dropzone';
import './file_upload.css';

const FileUpload = (props) => {
  const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
    maxFiles:1,
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      props.setfilePath(acceptedFiles[0])
      } 
    })

    acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  
  return (
        <div className="upload-box">
          {acceptedFiles.length>0?
          <img src={URL.createObjectURL(acceptedFiles[0])} style={{maxWidth:"340px"}} alt={acceptedFiles[0].name} />
          :
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some file here...</p>
              <button className="PostStatusInputSubmitButton" type="button" onClick={open}>
                Open File Dialog
              </button>
            </div> 
          </section>
          } 
        </div>
  )
}

export default FileUpload;