import { useState, useEffect} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import ReplyerEmptyFace from './replyEmptyFace'
import SinglePost from "./single_post"
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const InsideStrip = (props) => {
  const [StripPosts, setposts] = useState([]);
  const [StripEnd, setEnd] = useState(Infinity);
  const [data, setdata] = useState([]);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
      where("name", '==','PostMinted'), 
      where("replyNFTTokenId", '==',props.postid), 
      where("replyNFTSmartContract", '==', process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase()));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setdata(newData);
      });
    return () => unsubscribe();
  }, [props.postid]);

  const getListRepliestoPost = () => {
    const results = data
    var listRepliesNew = []
    var local_post_id
    for (var face of results) {
        local_post_id = face["PostId"]
        listRepliesNew.push(local_post_id)
    }

    return listRepliesNew;
  };

  const updateposts = () => {
     var _StripPosts = []        
     var _local_replies = getListRepliestoPost()
     _local_replies = _local_replies.slice();

     if (props.addrootimage) {
         _local_replies.unshift(props.postid);
     }

      if (_local_replies.length>StripPosts.length) {
        for (const _post_id of _local_replies) {
            if (_post_id !== "0") {
                var _post = {};      
                _post["post_id"] = _post_id
                _StripPosts.push(_post)
            }      
        }             

        // Sort _StripPosts by increasing post_id
        _StripPosts.sort((a, b) => a.post_id - b.post_id);
            
        setposts(_StripPosts)     
        setEnd(_StripPosts.length+1)
     }        
  };

  updateposts()
  

  if (StripPosts.length>0) {
    return (
            <>
                {
                    StripPosts.map(function(local_post, index){
                        return (
                          (index < StripEnd)?<SinglePost lastPost={index===StripPosts.length-1} readOnly={props.readOnly} allowExpansion={props.allowExpansion} showPostThreadLine={props.showPostThreadLine} showPostTime={props.showPostTime} key={local_post["post_id"]} setStripRepliersNb={setEnd} postid={local_post["post_id"]} index={index}/>:""
                        )
                    }
                    )
                }  
            {(StripEnd > StripPosts.length & !props.readOnly)?<ReplyerEmptyFace showBranch={props.showPostThreadLine} smartReplyNFTContract={process.env.REACT_APP_POLYGON_CONTRACT} smartReplyToken={props.postid} parentUpdate={updateposts} />:""}
            </>
          );
      } else if (StripPosts.length===0)
      {
        return (
          <> 
            {(StripEnd > StripPosts.length & !props.readOnly)?<ReplyerEmptyFace showBranch={props.showPostThreadLine} smartReplyNFTContract={process.env.REACT_APP_POLYGON_CONTRACT} smartReplyToken={props.postid} parentUpdate={updateposts} />:""}
          </>
        )
      } else {
        return (
            <div className="WaitingObject">
              <ClipLoader size={25} color="#2081E2" />
            </div>
          )
    } 
  }


InsideStrip.defaultProps = {
    addrootimage: false,
    readOnly: false,
    showPostTime: true,
    allowExpansion:true
}
  
export default InsideStrip;