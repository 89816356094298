import { useState } from "react";
import { notification } from 'antd';
import {
  Input,
  Button,
} from 'antd';
import './modalStatus.css';
import { useAccount, useBalance, useContractRead, useContractWrite } from 'wagmi'
import { useQuery } from 'react-query'
import {
  query,
  collection,
  where,
  limit,
  orderBy,
  getDocs
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const ModalStatus = (props) => {
  const { address } = useAccount()

  const [userStatus, setStatus] = useState("");
  const [waiting, setWaiting] = useState(false);
  const { refetch } = useBalance({
    address: address,
    enabled: false
  })
  
  const fetchStatus = async () => {
    const query_data = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
    where("name", '==','ProfileStatusPosted'), 
    where("Author", '==', address.toLowerCase()), 
    orderBy("blockNumber", "desc"),
    limit(1));

    const results = await getDocs(query_data);
    var firstItem = []
    if (results.docs[0]) {
        firstItem = results.docs[0].data();                
    }
    return firstItem
  }

  const { data:dataProfile } = useQuery(['ProfileStatusPosted',
  address
  ], 
  () => fetchStatus()
  )

  const { data:dataFee, isFetched } = useContractRead(
    {
      address: process.env.REACT_APP_POLYGON_CONTRACT.toLowerCase(),
      abi: [{
        "inputs": [],
        "name": "profile_fee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
      }],
      functionName: 'profile_fee'            
  })

  var strFees = "0"
  if (isFetched && dataFee) {
    strFees = dataFee.toString()
  }

  const { write:submitProfile } = useContractWrite({
    address: process.env.REACT_APP_POLYGON_CONTRACT,
    abi: [{
      "inputs": [
        {
          "internalType": "string",
          "name": "profileStatus",
          "type": "string"
        }
      ],
      "name": "setProfileStatus",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    }],
    functionName: 'setProfileStatus',
    value: strFees,
    enabled: (isFetched && dataFee && userStatus !== ""),
    args: [        
      userStatus
    ],
    onSubmitPressed(data) {
      notification
      .info({message: 'Transaction for status submitted to your crypto wallet.', duration: 8, placement:'topLeft'})
    },
    onError(error) {
      notification
      .error({message: "Error: " + error.code + " " + error.message, duration: 5, placement:'topLeft'})
    },
    onSuccess(data) {
      notification
      .success({message:  'Status was submitted to the blockchain (hash: '+data['hash']+').', duration: 2, placement:'topLeft'})
      notification
      .info({message:  'Website will be updated when block is approved.', duration: 5, placement:'topLeft'})
    }
    })

    
  const postStatus = async () => {
    if (userStatus === "") {
      notification
      .error({message: 'Please write a status first.', duration: 8, placement:'topLeft'})

    } else {
      submitProfile()
    }
  }

  const onSubmitPressed = async () => {
    const balance = await refetch()
    if (parseFloat(balance['formatted']) === 0) {
        notification
        .error({message: 'Your wallet is empty, you need some token to pay for micro-payments.', duration: 8, placement:'topLeft'})

      } else { 
      setWaiting(true)

      await postStatus();

      setWaiting(false);    
    }
  };

  var local_status = ''
  if (dataProfile !== undefined ) {
    local_status = dataProfile['status']
  }

  return (
    <>  
        <div className="StatusPoster">
          <Input.Group compact>
              <Input key={local_status} disabled={waiting} style={{ width: 'calc(100%)' }} 
                defaultValue={dataProfile?local_status:""}  
                placeholder='What is your status?'
                onChange={(event) => {setStatus(event.target.value)}} 
              />
              <Button className="PostStatusInputSubmitButton" type="primary" loading={waiting} onClick={() => {
                if (!waiting) {
                  onSubmitPressed()
                }
                }}>
                Mint New Status Sub-title
              </Button>
          </Input.Group>
        </div>
    </>
    )
}

export default ModalStatus;