import React  from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { QueryClientProvider, QueryClient } from 'react-query'
import smoothscroll from 'smoothscroll-polyfill';
import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { publicProvider } from '@wagmi/core/providers/public'
import { alchemyProvider} from'@wagmi/core/providers/alchemy'
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { polygon} from 'wagmi/chains'

const { chains, publicClient, webSocketPublicClient } = configureChains([polygon], [publicProvider({priority: 0}), alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY , priority: 1})])

const { connectors } = getDefaultWallets({
  appName: process.env.REACT_APP_RAINBOW_APPNAME,
  projectId: process.env.REACT_APP_RAINBOW_PROJECTID,
  chains
});

const config = createConfig({
  autoConnect: true,
  publicClient: publicClient,
  connectors: connectors,
  webSocketPublicClient: webSocketPublicClient
})
 
// Create a client
const staleduration = 1000 * 60 * 5;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: staleduration
    },
  },
});

// kick off polyfill to enable smooth scrolling in safari
smoothscroll.polyfill();

const Application = () => {
  return (
          <WagmiConfig config={config}>
            <RainbowKitProvider chains={chains}>
              <QueryClientProvider client={queryClient}>
                    <App isServerInfo />
              </QueryClientProvider>
            </RainbowKitProvider>
          </WagmiConfig>
  );
};

ReactDOM.render(<Application />,document.getElementById("root"));

