import {ImDiamonds} from "react-icons/im"
import {HiArrowSmRight} from "react-icons/hi"
import './profileTipsIn.css';
import { useState, useEffect} from "react";
import {
  query,
  collection,
  where,
  onSnapshot
} from "firebase/firestore";
import {db} from '../components/firestore_db';

const ProfileTipsIn = (props) => {
  const [data, setdata] = useState([]);

    useEffect(() => {
      const q = query(collection(db, process.env.REACT_APP_FIREBASE_COLLECTION), 
        where("name", '==','NFTTipped'), 
        where("receiver", '==',props.walletAddress.toLowerCase()));
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const newData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setdata(newData);
        });
      return () => unsubscribe();
    }, [props.walletAddress]);

    return (
    <div className="number_tips_in">
         <div className="arrowLeft"><HiArrowSmRight width="30px"/></div><div className="diamond_in"><ImDiamonds width="30px" /></div>{data.length}
    </div>
    )
  }
  
  export default ProfileTipsIn