import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Strip from '../components/strip'  
import './list_strips.css';
import InfiniteScroll from "react-infinite-scroll-component";

const added_length = 3

const ListStrips = (props) => {
  const [visiblestrips, setvisiblestrips] = useState(props.list_post_ids.slice(0,added_length+1));
  
  const list_post_ids = props.list_post_ids
  
  const fetchMoreData = () => {
    if (list_post_ids!==-1) {
      const original_length = list_post_ids.length
      const visible_length = visiblestrips.length
      const new_visiblestrips = visiblestrips.concat(list_post_ids.slice(visible_length, Math.min(original_length,visible_length+added_length)));

      setvisiblestrips(new_visiblestrips)
    }
  }
  if (list_post_ids !== -1) {
    return (
                <InfiniteScroll
                    dataLength={visiblestrips.length}
                    next={fetchMoreData}
                    hasMore={visiblestrips.length!==list_post_ids.length} 
                    scrollThreshold="325px"
                  >
                    {
                      visiblestrips.map(function(post_id, index){ 
                        return (    
                        <Strip key={post_id} postid={post_id} />
                        )

                      }
                      )
                    }    
                </InfiniteScroll>                
        );    
      } else {
        return (
          <div className="WaitingObject">
            Fetching blockchain data<br/>
            <ClipLoader size={25} color="#2081E2" />
          </div>
          )
    }
  }
export default ListStrips;