import {useState } from "react";
import { FiEdit3 } from 'react-icons/fi';
import ModalPost from "./modalPost"
import "./editer.css"

const Editer = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

    return (
      <>
        {!props.readOnly?
          <>
          <div className="bubbleEdit" onClick={() => {setIsModalVisible(true)}}>
                <FiEdit3 size="20px"/>
          </div>            
          <ModalPost postStatus={props.postStatus} smartReplyNFTContract={process.env.REACT_APP_POLYGON_CONTRACT} smartEditToken={props.postid} parentUpdate={props.parentUpdate} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
          </>
          :
          <div className="bubbleNoEdit">
            <FiEdit3 size="20px"/>
          </div>
        }
      </>
      )
}

export default Editer;